import { Injectable } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { DeviceProvider } from '../providers/device.provider';
import { AuthenticationProvider } from '../providers/authentication.provider';
import { UserSessionProvider } from '../providers/session/user-session.provider';
import { ModalController } from '@ionic/angular';
import { AuthPage } from '../modals/auth/auth.page';
import { TimeoutPage } from '../modals/timeout/timeout.page';

@Injectable({
  providedIn: 'root'
})
export class IdleService {
  public timedOut = false;
  private modal: HTMLIonModalElement;
  private timeoutModal: HTMLIonModalElement;
  private idleTime: number;
  public timeoutTime: number | false;

  
  constructor(
    public idle: Idle,
    private deviceProvider: DeviceProvider,
    private authenticationProvider: AuthenticationProvider,
    private userSessionProvider: UserSessionProvider,
    private modalCtrl: ModalController
  ) {
  }

  async run() {
    const device = await this.deviceProvider.getCurrent('idle service :: run');
    this.idleTime = (this.idleTime) ? this.idleTime : await this.getDeviceIdle(device);
    this.timeoutTime = (this.timeoutTime) ? this.timeoutTime : await this.getDeviceTimeout(device);
    if (this.idleTime != 0) {
      this.idle.setIdle(this.idleTime);
    }

    this.idle.setTimeout(this.timeoutTime);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onTimeout.subscribe(() => {
      this.timedOut = true;

      if (this.isActive()) {
        this.showAuth();
      } else {
        this.reset();
      }
    });

    this.idle.onIdleStart.subscribe(async () => {
      if (this.isActive()) {
        this.idle.clearInterrupts();
        await this.createTimeoutModal();

        this.timeoutModal.onDidDismiss()
          .then(() => {
            this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

            if (!this.timedOut) {
              this.reset();
            }
          });
      }
    });

    this.reset();
  }

  reset(): void {
    this.idle.watch();
    this.timedOut = false;
  }

  showAuth() {
    this.timedOut = true;
    this.initModal();
  }

  async getDeviceIdle(
    device: Parse.Object
  ): Promise<number> {
    return (device) ? await device.get('idle') : this.deviceProvider.parseProvider.device.defaultIdle;
  }

  async getDeviceTimeout(
    device: Parse.Object
  ): Promise<number|false> {
    const timeout = (device) ? await device.get('timeout') : this.deviceProvider.parseProvider.device.defaultTimeout;
    return (timeout === 0) ? false : timeout;
  }

  async initModal() {
    this.timedOut = true;
    const currentUsers = this.authenticationProvider.currentUsers;
    const data: any = {
      currentUsers: currentUsers
    };

    await this.createModal(data, 'timeout', null);

    this.modal.onDidDismiss()
      .then(() => this.reset());
  }

  isActive(): boolean {
    return this.userSessionProvider.activeUser;
  }

  async createModal(
    data: any,
    type: string,
    title: string
  ): Promise<void> {
    this.modal = await this.modalCtrl.create({
      component: AuthPage,
      componentProps: {
        'type': type,
        'data': data,
        'title': title
      },
      backdropDismiss: false,
      cssClass: ['authModal'],
      keyboardClose: true
    });
    return await this.modal.present();
  }

  async createTimeoutModal() {
    if (this.timeoutModal) {
      this.timeoutModal.dismiss();
      delete this.timeoutModal;
    }

    this.timeoutModal = await this.modalCtrl.create({
      component: TimeoutPage,
      backdropDismiss: true,
      cssClass: ['timeoutModal'],
      keyboardClose: false,
      componentProps: {
        'timeoutTime': this.timeoutTime,
        'idle': this.idle
      }
    });

    await this.timeoutModal.present();

    return this.timeoutModal;
  }
}

