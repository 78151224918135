import { Injectable } from '@angular/core';
import * as Parse from 'parse';

@Injectable({
    providedIn: 'root'
})
export class ParseColourProvider {

    async get(itemGroup: string): Promise<Parse.Object> {
        const query = new Parse.Query('Colour');
        query.equalTo('itemGroup', itemGroup);

        return await query.first();
    }

    async getValueByGroup(itemGroup: string): Promise<string> {
         const colour = await this.get(itemGroup);
         return colour.get('hexColour');
    }

    async getAll(): Promise<Parse.Object[]> {
        const query = new Parse.Query('Colour');
        query.limit(1000);
        query.include('itemGroup');
        query.include('hexColour');
        return await query.find();
      }

    async save(itemGroup: string, value: number) {
        const colour = await this.get(itemGroup);

        if (colour) {
            colour.set('itemGroup', itemGroup);
            colour.set('hexColour', value);
            await colour.save();
        } else {
            await this.create(itemGroup, value);
        }
    }

    async create(itemGroup: string, value: number) {
        const ColourObject = Parse.Object.extend('Colour');
        const colour = new ColourObject();
        colour.set('itemGroup', itemGroup);
        colour.set('hexColour', value);
        await colour.save();
    }
}
