<ion-header>
  <ion-toolbar class="ion-no-safe-area">
    <ng-container *ngIf="!voiceSearch">
      <ion-input (keyup.enter)="searchSubmit()" clearInput #searchInput (ngModelChange)="searchInputChange($event)" name="search" placeholder="Search" [(ngModel)]="search"
      type="search" autocapitalize="off" autocomplete="off" autocorrect="off" aria-label="search-input"></ion-input>
      <ion-buttons slot="end">
        <ion-button (click)="closeBrowseModal()" fill="clear">Done</ion-button>
      </ion-buttons>
    </ng-container>
    <ion-grid *ngIf="voiceSearch"> 
      <ion-row>
        <ion-col size="9" >
          <span *ngIf="matches" [innerHtml]="matches"></span>
          <span *ngIf="!matches">Listening...</span>
        </ion-col>
        <ion-col class="ion-text-end">
          <p class="done-button" (click)="closeBrowseModal()" class="ion-no-margin">Done</p>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="!search && !matches" class="ion-padding">
  <ion-list *ngIf="menuProvider.menuData" #list>

    <ion-item *ngIf="!menuProvider.isRoot()" (click)="menuProvider.back()">
      <ion-label [innerHtml]="'Previous'"></ion-label>
      <ion-icon name="arrow-back" slot="start" class="menuicon"></ion-icon>
    </ion-item>

    <ion-item-sliding *ngFor="let item of menuProvider.menuData.display; let i = index">
      <ion-item (click)="next(i, item.object.get('hasChild'))">
        <ion-label [innerHtml]="item.name"></ion-label>
        <ion-icon name="arrow-forward" slot="end" *ngIf="item.object.get('hasChild')"></ion-icon>
      </ion-item>
      <ion-item-options *ngIf="item.object.get('hasChild')">
        <ion-item-option color="light" (click)="next(i, false, list)">
          <ion-label color="primary" slot="end">View all</ion-label>
        </ion-item-option>
      </ion-item-options>
    </ion-item-sliding>

  </ion-list>
</ion-content>

<ion-content *ngIf="search || matches">
  <ion-list *ngIf="matches">
    <ion-list-header>
      <ion-buttons>
        <ion-button fill="clear" (click)="resetVoiceSearch()">
          <ion-label color="danger">Reset</ion-label>
        </ion-button>
        <ion-button [disabled]="results.length == 0" fill="clear" (click)="searchSubmit()">
          <ion-label color="primary">Go</ion-label>
          <ion-icon name="arrow-forward" color="primary"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-list-header>
    <ion-progress-bar [value]="progress" reverse="true" type="determinate"></ion-progress-bar>
  </ion-list>
  <ion-list data-cy="browseResults">
    <ion-item *ngFor="let item of results" (tap)="handleTap(item)" (press)="open(item)">
      <ion-avatar *ngIf="imagesEnabled" slot="start">
        <ion-img *ngIf="getImageUrl(item)" [src]="getImageUrl(item)" ></ion-img>
        <ion-img *ngIf="!getImageUrl(item)" src="/assets/icon/favicon.png" ></ion-img>
      </ion-avatar>
      <ion-text [innerHtml]="outputItem(item)"></ion-text>
    </ion-item>
  </ion-list>
</ion-content>