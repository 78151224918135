import { Injectable, NgZone } from '@angular/core';
import { MenuData } from '../interfaces/menuData';
import { ParseProvider } from './parse.provider';
import { MenuTree } from '../interfaces/menuTree';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class MenuProvider {

  public menuData: MenuData;
  private menuTree: MenuTree[];

  constructor(
    private parseProvider: ParseProvider,
    private navCtrl: NavController,
    private zone: NgZone
  ) {
    this.initialiseSubscriptions();
    this.parseProvider.list.initTree();
  }

  initialiseSubscriptions() {
    this.parseProvider.list.treeObservable.subscribe(tree => this.menuTreeSubscriber(tree));
  }

  menuTreeSubscriber(tree: Array<MenuTree>) {
    this.zone.run(() => {
      this.menuTree = tree;
      this.initMenu();
    });
  }

  initMenu() {
    this.menuData = {
      level: 2,
      parent: 'root',
      breadcrumbs: [],
      display: this.menuTree
    };
  }

  next(
    index: number,
    hasChild: boolean,
    target?: boolean
  ) {
    if (
      hasChild
      && this.menuData.display[index].children
    ) {
      this.menuData.level++;
      this.menuData.breadcrumbs.push(this.menuData.display[index]);
      this.menuData.parent = this.menuData.display[index];
      this.menuData.display = this.menuData.display[index].children;
    } else {
      const list = this.menuData.display[index];
      let suffix = '';
      if (list.childIds) {
        suffix = '/' + list.childIds.join(',');
      }
      console.log('/view/list/' + list.id + suffix);
      if (!target) {
        this.navigateTo('/view/list/' + list.id + suffix);
        this.zone.run(async () => {
          await this.navigateTo('/view/list/' + list.id + suffix);
        });
      }
      return '/view/list/' + list.id + suffix;
    }
  }

  back(): void {
    if (this.isRoot()) {
      return;
    }
    if (this.menuData.breadcrumbs.length <= 1) {
      this.initMenu();
      return;
    }
    this.menuData.level--;
    this.menuData.breadcrumbs.splice(-1);
    const parent = this.menuData.breadcrumbs.slice(-1).pop();
    this.menuData.parent = parent;
    this.menuData.display = parent.children;
  }

  isRoot(): boolean {
    return this.menuData.parent === 'root';
  }

  navigateTo(route: string) {
    console.log(route);
    // TODO :: Close menu?
    this.zone.run(async () => {
      await this.navCtrl.navigateRoot(route);
    });
  }
}
