import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ParseProvider } from './parse.provider';
import { CurrencyPipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CurrencyProvider {

  private currency: string;

  constructor(
    private parseProvider: ParseProvider,
    public currencyPipe: CurrencyPipe
  ) {

  }

  async init() {
    this.currency = await this.getCurrency();
  }

  getSymbol() {
    return environment.currencySymbol;
  }

  getCode() {
    return environment.currency;
  }

  formatPrice(n: number): string {
    if (n && n !== 0) {
      const result: string = n.toFixed(2).toString().replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
      return result;
    } else {
      return '0.00';
    }
  }

  async saveCurrency(value: string) {
    await this.parseProvider.saveConfigValue('currency', value)
    this.currency = value;
  }

  async getCurrency(): Promise<string> {
    if (this.currency) {
      return this.currency;
    }

    const currency = await this.parseProvider.getConfigValue('currency');

    if (!currency) {
      return this.getCode();
    }

    return currency;
  }

  transform(value: any, showSymbol: boolean = true, noDecimals?: boolean): string {
    const display = showSymbol ? 'symbol-narrow' : '';

    if (!value) {
      value = 0;
    }

    if (typeof value === 'string' && value.search(',') >= 0 ) {
      value = value.replace(/\,/g,'');
    }

    if (noDecimals) {
      return this.currencyPipe.transform(value, this.currency, display, '1.0-0');
    }

    return this.currencyPipe.transform(value, this.currency, display);
  }
}
