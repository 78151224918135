import { environment } from './../environments/environment';
import { NgModule, ErrorHandler, Injectable, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins//status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ModalsModule } from './modals/modals.module';
import { ComponentsModule } from './components/components.module';
import { FilterComponent } from './popovers/filter/filter.component';
import { StarPRNT } from '@awesome-cordova-plugins/star-prnt/ngx';
import { PipesModule } from './pipes/pipes.module';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { SpeechRecognition } from '@awesome-cordova-plugins/speech-recognition/ngx';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { Toast } from '@awesome-cordova-plugins/toast/ngx';
import { SecureStorage } from '@awesome-cordova-plugins/secure-storage/ngx';
import { NgIdleModule } from '@ng-idle/core';
import { IonicStorageModule } from '@ionic/storage-angular';
import { WebIntent } from '@awesome-cordova-plugins/web-intent/ngx';
import { CurrencyPipe } from '@angular/common';
import { MarkdownModule } from 'ngx-markdown';
import { HttpClient } from '@angular/common/http';

import * as Sentry from '@sentry/browser';
import { IdleService } from './services/idle.service';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';


Sentry.init({
  dsn: 'https://b07fd9776d8f4f20992fb01dc50adaac@o213693.ingest.sentry.io/1341794',
  release: 'mdlpos@' + environment.version,
  environment: environment.project,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 0.3,

  integrations: [
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
    Sentry.browserTracingIntegration()
  ],
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    Sentry.captureException(error.originalError || error);
    throw error;
  }
}

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any> {
      'pinch': { enable: false },
      'rotate': { enable: false },
      'press': { time: 1000 }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    FilterComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HammerModule,
    ModalsModule,
    ComponentsModule,
    PipesModule,
    NgIdleModule.forRoot(),
    IonicStorageModule.forRoot(),
    MarkdownModule.forRoot({ loader: HttpClient })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    StarPRNT,
    ScreenOrientation,
    SpeechRecognition,
    OpenNativeSettings,
    Toast,
    SecureStorage,
    IdleService,
    WebIntent,
    CurrencyPipe,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
