import { Injectable, NgZone } from '@angular/core';
import { Message } from '../interfaces/message';
import { Subject } from 'rxjs';
import { CartItemCollection } from '../interfaces/cartItemCollection';
import { ParseProvider } from './parse.provider';
import { ToastProvider } from './toast.provider';
import { CustomerProvider } from './customer.provider';
import { LoadingProvider } from './loading.provider';
import { AlertController, ModalController } from '@ionic/angular';
import { TaxProvider } from './tax.provider';
import { DeviceProvider } from './device.provider';
import { Storage } from '@ionic/storage-angular';
import { DiscountProvider } from './discount.provider';
import { Customer } from '../interfaces/customer';

@Injectable({
  providedIn: 'root'
})

/**
 * [CartProvider description]
 */
export class CartProvider {

  public items: CartItemCollection = {};
  public messages: Subject<Message>;
  public objectKeys = Object.keys;
  public customer: Customer;
  public customerDescription: string;
  public showCheckoutButton = true;
  public balanceRemaining: number;
  public originalTotal: number;
  public taxLabel: string;
  public cartTax: boolean;
  public parseCart: Parse.Object;
  public parseDevice: false | Parse.Object;
  public discounts: Object = {};
  public total: number;
  public clearCartSave: boolean;
  private initialised = false;
  public numberOfItems: number;

  private exTaxTotal: number;

  /**
   * 
   * @param parseProvider 
   * @param toastProvider 
   * @param customerProvider 
   * @param loadingProvider 
   * @param alertController 
   * @param zone 
   * @param taxProvider 
   * @param deviceProvider 
   * @param modalCtrl 
   */
  constructor(
    private parseProvider: ParseProvider,
    private toastProvider: ToastProvider,
    private customerProvider: CustomerProvider,
    private loadingProvider: LoadingProvider,
    private alertController: AlertController,
    private zone: NgZone,
    private taxProvider: TaxProvider,
    private deviceProvider: DeviceProvider,
    private modalCtrl: ModalController,
    private storage: Storage,
    private discountProvider: DiscountProvider,
  ) {
    this.messages = new Subject();
    this.parseProvider.cart.messages.subscribe(this.onParseCartMessage.bind(this));
  }

  /**
   * [init description]
   *
   * @return  {[type]}  [return description]
   */
  async init(
    parseDevice: Parse.Object
  ) {
    if (!this.initialised) {
      this.parseDevice = parseDevice;
      
      if (this.parseDevice) {
        this.taxLabel = await this.parseDevice.get('taxLabel');
        this.cartTax = await this.parseDevice.get('cartTax');
        this.clearCartSave = this.parseDevice.get('clearCartSave');
      }

      await this.storage.create();
      await this.getStorageItems();
      this.initEventListener();
      this.initialised = true;
    }
  }

  initEventListener() {
    window.addEventListener('starPrntData', (e) => {
      this.zone.run(() => {
        if (e['dataType'] == 'barcodeDataReceive') {
          const barcode = e['data'].replace(/\*/g, '');
          this.addByBarcode(barcode, 1);
        }
      })
    });
  }

  /**
   * 
   * @param message 
   */
  onParseCartMessage(message: Message) {
    if (message.type === 'save') {
      // TODO :: Make this a setting
      // this.clearCart();
      this.toastProvider.showToast('Saved');
    }
  }

  /**
   * 
   * @param item 
   * @param qty 
   * @param image 
   * @param options 
   */
  async add(
    item: Parse.Object,
    qty: number,
    image?: string,
    options?: object,
    barcode?: boolean
  ) {
    const itemKey = this.getItemKey(item, options);
    if (this.items[itemKey]) {
      this.items[itemKey].qty += qty;
    } else {
      if (!image) {
        const itemImage = await item.get('image');
        image = itemImage ? itemImage.url : '';
      }

      let price = item.get('price');

      if (item.hasOwnProperty('configPrice')) {
        price = item['configPrice'];
      }

      const rowTotal = this.calculateRowTotal(price, qty);

      const cartItem = {
        item: item,
        qty: qty,
        name: item.get('name'),
        price: await this.getPrice(price, 'price', item.get('noDiscount'), item.get('itemGroup')),
        options: options,
        image: image,
        originalPrice: await this.getPrice(price, 'original', item.get('noDiscount'), item.get('itemGroup')),
        rowTotal: this.formatPrice(rowTotal),
        rowTotalExTax: this.formatPrice(
          item.get('taxClass') == 'zero-rate'
            ? rowTotal
            : await this.taxProvider.calculate(item.get('taxClass'), rowTotal, 'netFromGross')
        ),
        noDiscount: item.get('noDiscount'),
        taxClass: item.get('taxClass'),
        itemGroup: item.get('itemGroup')
      };
      this.items[itemKey] = cartItem;
    }
    if (!barcode) {
      this.toastProvider.showToast('✅  ' + item.get('name') + ' added to cart');
    }
    this.refreshTotal();
  }

  /**
   * Update saved cart in parse
   *
   * @return  {Promise<Parse.Object>}
   */
  async updateParseCart() {
    const result = await this.parseProvider.cart.updateParseCart(this.items, this.customer, this.parseCart);
    if (result) {
      this.toastProvider.showToast('Updated');
      if (this.clearCartSave) {
        this.clearCart();
      }
    }
    return result;
  }

  /**
   * 
   * @param price 
   * @param type 
   * @param noDiscount 
   */
  async getPrice(
    price: string,
    type: string,
    noDiscount?: boolean,
    itemGroup?: string
  ) {
    let result: string;

    if (!price) {
      price = '0';
    }

    const group = (this.customer) ? await this.getGroup(this.customer) : null;
    const discount = (group) ? this.getDiscountPerc(itemGroup) : null;
    const discounted = (group) ? group.get('discounted') : null;
    switch (type) {
      case 'price':
        result = (discount && discounted && !noDiscount) ? this.calculateDiscountedPrice(discount, price) : price;
        break;
      case 'original':
        result = (discount && discounted && !noDiscount) ? price : null;
        break;
    }

    return result;
  }

  /**
   * 
   * @param customer 
   */
  async getGroup(
    customer: any
  ) {
    const group = (customer.group) ? await this.customerProvider.getGroup(customer.group.id) : null;
    return group;
  }

  /**
   * 
   * @param discount 
   * @param price 
   */
  calculateDiscountedPrice(
    discount: string,
    price: string
  ): string {
    const discountVal = parseFloat(discount);
    const priceVal = parseFloat(price);
    const resultVal = priceVal - (priceVal * (discountVal / 100));
    return this.formatPrice(resultVal);
  }

  /**
   *
   * @param customer
   */
  async setCustomer(customer: Customer) {
    const existingCustomer = this.customer;
    if (existingCustomer) {
      this.confirmAddCustomer(customer);
    } else {
      this.applyCustomerToCart(customer, false);
    }

    if (customer['group']) {
      this.toastProvider.showToast('Customer qualifies for member discounts. These can be entered in the Cart for each item row');
    }
  }

  /**
   *
   * @param customer 
   * @param existingCustomer 
   */
  async applyCustomerToCart(
    customer: Customer,
    existingCustomer: boolean
  ) {
    if (existingCustomer) {
      await this.resetCartDiscounts();
    }

    this.customer = customer;
    this.customerDescription = this.getCustomerInfo(this.customer);
    this.discounts = customer['group'] ? await this.discountProvider.getDiscounts(this.customer) : {};
    await this.applyGroupDiscounts();
    // ToDo: Make the below work without errors in console
    // this.toastProvider.showToast('Customer has been applied - ' + this.customer.name);
    this.toastProvider.showToast('Customer has been applied');
    this.closeCustomerModal();
  }

  getCustomerInfo(
    customer: Customer
  ) {
    return (customer.email === "") ? customer.firstName + ' ' + customer.lastName : customer.email;
  }

  /**
   * [closeCustomerModal description]
   *
   * @return  {[type]}  [return description]
   */
  async closeCustomerModal() {
    const modal = await this.modalCtrl.getTop();
    if (modal) {
      this.modalCtrl.dismiss();
    }
  }

  /**
   * [resetCartDiscounts description]
   *
   * @return  {Promise<void>}  [return description]
   */
  async resetCartDiscounts(): Promise<void> {
    for (const key in this.items) {
      if (!this.items.hasOwnProperty(key)) {
        continue;
      }

      const item = this.items[key];

      if (item.originalPrice) {
        item.price = item.originalPrice;
        item.originalPrice = null;
      }
    }

    this.refreshTotal();
    return;
  }

  /**
   * 
   * @param customer 
   */
  async confirmAddCustomer(customer: Customer) {
    const alert = await this.alertController.create({
      header: 'Add Customer',
      message: 'There is already a customer applied to this cart, would you like to continue?',
      buttons: [
        { text: 'Cancel', role: 'cancel', cssClass: 'secondary', handler: () => { } },
        { text: 'OK', handler: () => { this.applyCustomerToCart(customer, true) } }
      ]
    });
    await alert.present();
  }

  /**
   * [applyGroupDiscounts description]
   *
   * @return  {Promise<boolean>}  [return description]
   */
  async applyGroupDiscounts(): Promise<boolean> {
    for (const key in this.items) {
      if (!this.items.hasOwnProperty(key)) {
        continue;
      }

      const item = this.items[key];

      if (!item.noDiscount && !item.originalPrice) {
        item.originalPrice = await this.getPrice(item.price, 'original', item.noDiscount, item.itemGroup);
        item.price = await this.getPrice(item.price, 'price', item.noDiscount, item.itemGroup);
      }
    }
    this.refreshTotal();
    return;
  }

  /**
   * 
   * @param item 
   * @param options 
   */
  getItemKey(item, options): string {
    let key = item.id;
    if (options) {
      Object.keys(options).forEach(optionKey => {
        key += '-' + optionKey + '-' + options[optionKey].id;
      });
    }
    return key;
  }

  /**
   * 
   * @param id 
   * @param qty 
   * @param image 
   * @param options 
   */
  async addById(
    id: string,
    qty: number,
    image?: string,
    options?: object
  ): Promise<boolean> {
    let result = false;
    let item: Parse.Object | boolean = false;
    try {
      item = await this.parseProvider.item.get(id);
    } catch (e) {
      console.log(e.message);
    }

    if (item && item.get('visibility') != 5) {
      result = await this.processItemOptions(item, qty, image, options)
    }

    return result;
  }

  async processItemOptions(
    item: Parse.Object,
    qty: number,
    image?: string,
    options?: object
  ): Promise<boolean> {
    let result = false;
    try {
      if (item.get('type') !== 'configurable') {
        if (!item.get('type')) {
          const parent = await this.parseProvider.item.getParent(item);

          if (parent.get('visibility') == 5) {
            return result;
          }

          options = this.parseProvider.item.getOptions(item, parent)

          if (parent.get('price') != item.get('price')) {
            parent['configPrice'] = item.get('price');
          }

          item = parent;
        }

        this.add(item, qty, image, options, true);
        result = true;
      }
    } catch (e) {
      console.error(e);
    }

    return result;
  }

  async addByBarcode(
    barcode: string,
    qty: number,
    image?: string,
    options?: object
  ): Promise<boolean> {
    let result = false;
    let item: Parse.Object | boolean = false;
    item = await this.parseProvider.item.getByBarcode(barcode);

    if (item && item.get('visibility') != 5) {
      result = await this.processItemOptions(item, qty, image, options)
    }

    return result;
  }

  /**
   * 
   * @param productId 
   * @param qty 
   */
  setQty(
    productId: string,
    qty: number
  ) {
    if (qty < 1) {
      delete this.items[productId];
    } else {
      this.items[productId].qty = +qty;
    }
    this.refreshTotal();
  }

  /**
   * 
   * @param itemId 
   * @param qty 
   */
  incrementQty(
    itemId: string,
    qty?: number
  ) {
    if (!qty) {
      qty = 1;
    }
    if (this.items[itemId]) {
      this.items[itemId].qty += qty;
    }
    this.refreshTotal();
  }

  /**
   * 
   * @param productId 
   */
  decrementQty(productId: string) {
    if (this.items[productId].qty === 1) {
      delete this.items[productId];
    } else {
      this.items[productId].qty -= 1;
    }
    this.refreshTotal();
  }

  /**
   * 
   * @param productId 
   * @param price 
   */
  async updatePrice(
    productId: string,
    price: string
  ) {
    let originalPrice: string = this.items[productId].originalPrice;
    let priceEdited = false;
    if (!originalPrice) {
      originalPrice = this.items[productId].price;
      this.items[productId].originalPrice = originalPrice;
    }
    const validPrice = this.validateNewPrice(originalPrice, price);
    if (validPrice) {
      this.items[productId].price = price;
      priceEdited = true;
    } else {
      this.toastProvider.showToast("Invalid price");
      this.resetPrice(productId);
      priceEdited = false;
    }
    this.refreshTotal();
    return priceEdited;
  }

  /**
   * 
   * @param productId 
   */
  resetPrice(productId: string) {
    this.items[productId].price = this.items[productId].originalPrice;
    this.items[productId].originalPrice = null;
    this.refreshTotal();
  }

  /**
   * 
   * @param originalPrice 
   * @param price 
   */
  validateNewPrice(
    originalPrice: string,
    price: string
  ) {
    let result = false;
    const originalPriceVal = parseFloat(originalPrice);
    const priceVal = parseFloat(price);
    const discount = originalPriceVal - priceVal;
    if (priceVal === originalPriceVal) {
      return result;
    }
    if (discount <= originalPriceVal) {
      result = true;
    }
    return result;
  }

  /**
   *
   * @param productId 
   */
  removeProduct(productId: string) {
    delete this.items[productId];
    this.refreshTotal();
  }

  /**
   * [clearCart description]
   *
   * @return  {[type]}  [return description]
   */
  clearCart() {
    this.items = {};
    this.discounts = {};
    delete this.customer;
    delete this.parseCart;
    this.refreshTotal();
  }

  /**
   * [getTotalPrice description]
   *
   * @return  {number}  [return description]
   */
  getTotalPrice(): number {
    return this.total;
  }

  /**
   * [getTotalPriceExTax description]
   *
   * @return  {number}  [return description]
   */
  getTotalPriceExTax(): number {
    return this.exTaxTotal;
  }

  /**
   * [refreshTotal description]
   *
   * @return  { void }  [return description]
   */
  async refreshTotal() {
    let total = 0;
    let exTax = 0;
    this.numberOfItems = 0;

    for (const key in this.items) {
      if (!this.items.hasOwnProperty(key)) {
        continue;
      }

      const qty = this.items[key].qty;
      const price = this.items[key].price;
      const rowTotal = this.calculateRowTotal(price, qty);
      this.numberOfItems += qty;
      const exTaxRowTotal = this.items[key].taxClass == 'zero-rate'
        ? rowTotal
        : await this.taxProvider.calculate(this.items[key].taxClass, this.calculateRowTotal(price, qty), 'netFromGross');
      total += rowTotal;
      exTax += exTaxRowTotal;
      this.items[key].rowTotal = this.formatPrice(rowTotal);
      this.items[key].rowTotalExTax = this.formatPrice(exTaxRowTotal);
    }

    this.originalTotal = total;
    this.balanceRemaining = total;
    this.total = total;
    this.exTaxTotal = exTax;

    await this.setStorageItems();
    await this.loadingProvider.dismiss();
  }

  /**
   *
   * @param price
   * @param qty
   */
  calculateRowTotal(
    price: string,
    qty: number
  ) {
    return qty * parseFloat(price);
  }

  /**
   *
   * @param price 
   */
  formatPrice(price: number): string {
    if (price && price !== 0) {
      const result: string = price.toFixed(2).toString();
      return result;
    } else {
      return '0.00';
    }
  }

  /**
   *
   * @param items
   * @param customer
   * @param cart
   */
  async loadFromObject(
    items: CartItemCollection,
    customer: Customer,
    cart: Parse.Object
  ) {
    this.zone.run(() => {
      this.items = items;

      if (customer) {
        this.customer = customer;
        this.customerDescription = this.getCustomerInfo(this.customer);
      }

      this.parseCart = cart;
      console.log(this.parseCart);
      this.refreshTotal();
    });
  }

  unsetCustomer() {
    delete this.customer;
  }

  /**
   *
   * @param cart
   */
  async delete(cart: Parse.Object) {
    await this.parseProvider.cart.delete(cart.id);
  }

  /**
   * Get cart from local storage by key
   */
  async getStorageItems() {
    if (this.parseDevice) {
      const cartSharing = await this.parseDevice.get('cartSharing')
      let key = 'cart';

      if (!cartSharing) {
        const user = await this.parseProvider.user.getCurrentUser();
        key = user.id;
      }

      const cart = await this.storage.get(key);
      this.items = cart ? cart : {};
      this.refreshTotal();
    }
  }

  /**
   * Set cart in local storage by key
   */
  async setStorageItems() {
    if (this.parseDevice) {
      const cartSharing = await this.parseDevice.get('cartSharing')
      let key = 'cart';

      if (!cartSharing) {
        const user = await this.parseProvider.user.getCurrentUser();
        key = user.id;
      }

      this.storage.set(key, this.items);
    }
  }

  /**
   * Get discount perc for item group
   * 
   * @param {string} itemGroup
   * @returns {string}
   */
  getDiscountPerc(itemGroup: string): string {
    if (this.discounts[itemGroup]) {
      return this.discounts[itemGroup];
    }

    return null;
  }

  getCustomerObject(): Parse.Object | undefined {
    let object;

    if (this.customer && this.customer.hasOwnProperty('object')) {
      object = this.customer.object;
    }

    return object;
  }
}
