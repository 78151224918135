import { Injectable } from '@angular/core';
import { ParseProvider } from './parse.provider';
import { Block } from '../interfaces/block';
import { LiteBlock } from '../interfaces/liteBlock';

@Injectable({
  providedIn: 'root'
})
export class BlockProvider {

  constructor(
    public parseProvider: ParseProvider
  ) {
  }

  async set(
    block: Block, 
    device: Parse.Object
  ): Promise<Parse.Object> {
    return await this.parseProvider.block.set(block, device);
  }

  async get(
    objectId: string
  ): Promise<Parse.Object> {
    return await this.parseProvider.block.get(objectId)
  }

  getByDevice(device: Parse.Object) {
    return this.parseProvider.block.getByDevice(device)
      .then(result => {
        return result;
      }, error => {
        this.error(error.message);
      });
  }

  delete(block: Parse.Object) {
    return this.parseProvider.block.delete(block)
      .then(result => {
        return result;
      }, error => {
        this.error(error.message);
      });
  }

  update(input: Parse.Object, info: Block) {
    return this.parseProvider.block.update(input, info)
      .then(result => {
        return result;
      }, error => {
        this.error(error.message);
      });
  }

  error(message: string) {
    console.log('== BlockProvder ==', message);
  }

  async createLiteBlock(
    input: LiteBlock
  ): Promise<Parse.Object> {
    const device = await this.parseProvider.device.getCurrent();
    return await this.parseProvider.block.createLiteBlock(input, device);
  }

  async getDeviceLiteBlocks(
    device?: Parse.Object
  ): Promise<Parse.Object[]> {
    device = (device) ? device : await this.parseProvider.device.getCurrent();
    return await this.parseProvider.block.getDeviceLiteBlocks(device);
  }

  async getLiteBlock(
    id: string
  ) {
    return await this.parseProvider.block.getLiteBlock(id);
  }
}
