import { Injectable } from '@angular/core';
import { ParseProvider } from './parse.provider';
import { Store } from '../interfaces/store';
import { ToastProvider } from './toast.provider';


@Injectable({
  providedIn: 'root'
})
export class StoreProvider {

  constructor(
    private parseProvider: ParseProvider,
    private toastProvider: ToastProvider
  ) {
  }

  async create(
    store: Store
  ): Promise<Parse.Object> {
    return await this.parseProvider.store.create(store);
  }

  async get(
    storeId: string
  ): Promise<Parse.Object> {
    return await this.parseProvider.store.get(storeId);
  }

  async getAll(): Promise<Parse.Object[]> {
    return await this.parseProvider.store.getAll();
  }

  async init(
    device: Parse.Object
  ): Promise<boolean> {
    let result = false;
    let validStore = false;
    const store = await device.get('store');
    if (store){
      validStore = await this.exists(store.id);
      result = validStore;
    }
    if (!store || !validStore) {
      console.log('no store set');
      result = await this.checkAvailableStores(device);
    }
    return result;
  }

  async checkAvailableStores(
    device: Parse.Object
  ): Promise<boolean> {
    const stores = await this.getAll();
    const autoAssign = (stores.length === 1);
    return await this.autoAssign(autoAssign, stores, device);
  }

  async autoAssign(
    autoAssign: boolean,
    stores: Parse.Object[],
    device: Parse.Object
  ): Promise<boolean> { 
    let result = false;
    if (autoAssign) {
      const defaultStore = stores[0];
      device.set('store', defaultStore);
      await device.save();
      const storeName = await defaultStore.get('name');
      await this.toastProvider.temporary("Default store " + storeName + " has been assigned to this device");
      result = true;
    } else {
      device.unset('store');
      await device.save();
      await this.toastProvider.temporary("Warning - store not set for this device");
    }
    return result;
  }

  async delete(
    storeId: string
  ): Promise<Parse.Object> {
    return await this.parseProvider.store.delete(storeId);
  }

  async exists(
    storeId: string
  ): Promise<boolean> {
    return await this.parseProvider.store.exists(storeId);
  }
  
}