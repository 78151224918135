<ion-header>
  <ion-toolbar class="no-safe-area">
    <ion-grid>
      <ion-row>
        <ion-col (click)="closeReturnModal()" class="done-button" text-end align-self-center>
          Done
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ng-container *ngIf="ready" padding>
    <ion-list>
      <ion-item *ngFor="let item of returnItems">
        <ion-avatar slot="start">
          <ion-img *ngIf="getImageUrl(item.ordered)" [src]="getImageUrl(item.ordered)" ></ion-img>
          <ion-img *ngIf="!getImageUrl(item.ordered)" src="/assets/icon/favicon.png" ></ion-img>
        </ion-avatar>

        <ion-label *ngIf="!smallDevice">
          <h2 [innerHtml]="item.ordered.get('name')"></h2>
          <ion-chip class="option-chip" color="primary">
            <ion-label [innerHtml]="convertPrice(item.ordered.get('price'))"></ion-label>
          </ion-chip>
          <ng-container *ngIf="item.ordered.get('configurableOptions')">
            <ion-chip *ngFor="let optionKey of objectKeys(item.ordered.get('configurableOptions'))" class="option-chip">
              <ion-label [innerHtml]="item.ordered.get('configurableOptions')[optionKey].attribute.frontend_label + ' : ' + item.ordered.get('configurableOptions')[optionKey].label"></ion-label>
            </ion-chip>
          </ng-container>
          <h3>Qty available to return: <span [innerHtml]="item.availableReturnQty"></span></h3>
        </ion-label>

        <ion-label *ngIf="smallDevice">
          <h2 [innerHtml]="item.ordered.get('name')"></h2>
          <p [innerHtml]="convertPrice(item.ordered.get('price'))"></p>
          <ng-container *ngIf="item.ordered.get('configurableOptions')">
            <p *ngFor="let optionKey of objectKeys(item.ordered.get('configurableOptions'))" class="option-chip" [innerHtml]="item.ordered.get('configurableOptions')[optionKey].attribute.frontend_label + ' : ' + item.ordered.get('configurableOptions')[optionKey].label"></p>
          </ng-container>
          <h3>Qty available to return: <span [innerHtml]="item.availableReturnQty"></span></h3>
        </ion-label>

        <ion-badge slot="end">{{item.returnQty}}</ion-badge>
        <ion-buttons>
          <ion-button (click)="incrementQty(item)" color="medium" fill="outline">
            <ion-icon slot="icon-only" name="add"></ion-icon>
          </ion-button>
          <ion-button (click)="decrementQty(item)" color="medium" fill="outline">
            <ion-icon slot="icon-only" name="remove"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-item>
    </ion-list>
  </ng-container>
</ion-content>

<ion-footer>

  <ion-toolbar>
    <ion-button expand="block" (click)="continue()" margin-horizontal>
      <ion-label>
        Create Return
      </ion-label>
    </ion-button>
  </ion-toolbar>

</ion-footer>
