import { Injectable } from '@angular/core';
import { UserSessionProvider } from './session/user-session.provider';

@Injectable({
  providedIn: 'root'
})
export class SessionProvider {

  constructor(
    public user: UserSessionProvider
  ) {
  }

}
