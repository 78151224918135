import { Injectable } from '@angular/core';
import { ParseProvider } from './parse.provider';


@Injectable({
  providedIn: 'root'
})
export class TaxProvider {

  private rates: any = {};

  constructor(
    private parseProvider: ParseProvider
  ) {
  }

  async init() {
    return await this.parseProvider.tax.init();
  }

  async calculate(
    label: string,
    input: number,
    type: string
  ) {
    const rate = await this.getRate(label);
    let result: number;
    switch (type) {
      case 'taxFromGross':
        result = input - (input / (1 + rate / 100));
        break;
      case 'netFromGross':
        result = input / (1 + rate / 100);
        break;
      case 'totalFromNet':
        result = input * (1 + rate / 100);
        break;
    }
    return result;
  }

  async getRate(
    label: string
  ) {
    if (!label) {
      label = 'standard';
    }

    if (typeof this.rates[label] === 'undefined') {
      const tax = await this.parseProvider.tax.get(label);
      this.rates[label] = await tax.get('rate');
    }

    return this.rates[label];
  }

  clearLocal() {
    this.rates = {};
  }

  /**
   * Calculate VAT
   * 
   * @param {string} label
   * @param {Parse.Object[]} orderItems
   * @returns {Promise<number>}
   */
  async calculateVAT(label: string, orderItems: Parse.Object[]): Promise<Object> {
    let vatTotals = {
      total: 0
    };
    for (const item of orderItems) {
      if (item.get('taxClass') != 'zero-rate') {
        const rate = await this.getRate(item.get('taxClass'));
        const vat = (item.get('price') / (100 + rate)) * rate * item.get('qty');
        if (rate in vatTotals) {
          vatTotals[rate] += vat;
        } else {
          vatTotals[rate] = vat
        }

        vatTotals['total'] += vat;
      }
    }

    return vatTotals;
  }
}