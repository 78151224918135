<ion-header>
  <ion-toolbar class="no-safe-area">
    <ion-grid>
      <ion-row>
        <ion-col (click)="closeBlockModal()" class="done-button" text-end align-self-center>
          Cancel
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding" *ngIf="targetMode">

  <ion-list *ngIf="menuProvider.menuData" #list>

    <ion-item *ngIf="!menuProvider.isRoot()" (click)="menuProvider.back()">
      <ion-label [innerHtml]="'Previous'"></ion-label>
      <ion-icon name="arrow-back" slot="start" class="menuicon"></ion-icon>
    </ion-item>

    <ion-item *ngFor="let item of menuProvider.menuData.display; let i = index" (click)="next(i, item.object.get('hasChild'))">
      <ion-label [innerHtml]="item.name"></ion-label>
      <ion-icon name="arrow-forward" slot="end" *ngIf="item.object.get('hasChild')"></ion-icon>
      <ion-button (click)="next(i, false)" *ngIf="item.object.get('hasChild')" fill="outline">
        <ion-label>Select Parent</ion-label>
      </ion-button>
    </ion-item>

  </ion-list>
</ion-content>

<ion-content class="ion-padding" *ngIf="!targetMode">
  <ion-row class="ion-justify-content-center">
    <ion-col size="12" size-md="7">
      <ion-card class="edit-card" [ngStyle]="block.bgEnabled && { 'background-color':  block.backgroundColour }">
        <ion-img *ngIf="block.showImage" [src]="block.image" ></ion-img>
        <ion-card-content [class.inverted]="block.invert" [class.no-image]="!block.showImage" [ngStyle]="block.backgroundColour && { 'background-color':  'transparent' }">
          <ion-card-subtitle class="truncate" [innerHtml]="block.subtitle"></ion-card-subtitle>
          <ion-card-title class="truncate" [innerHtml]="block.title"></ion-card-title>
        </ion-card-content>
      </ion-card>
    </ion-col>
    <ion-col size="12" size-md="5">
      <ion-list>
        <ion-item>
            <ion-input [(ngModel)]="block.title" type="text" [value]="block.title" placeholder="Title" label="Title" labelPlacement="floating"></ion-input>
        </ion-item>
        <ion-item>
            <ion-input [(ngModel)]="block.subtitle" type="text" [value]="block.subtitle" placeholder="Subtitle" label="Subtitle" labelPlacement="floating"></ion-input>
        </ion-item>

        <ion-item>
          <ion-label>Image Picker</ion-label>
          <ion-button fill="clear" (click)="imagePick()">
            <ion-icon slot="icon-only" name="image"></ion-icon>
          </ion-button>
        </ion-item>

        <ion-item>
          <ion-label>Delete Image</ion-label>
          <ion-button color="danger" fill="clear" (click)="deleteImage()">
            <ion-icon slot="icon-only" name="trash"></ion-icon>
          </ion-button>
        </ion-item>
        
        <!-- Invert the Title ande Subtitle colours -->
        <ion-item>
          <ion-toggle [(ngModel)]="block.invert" [checked]="block.invert">Invert Text</ion-toggle>
        </ion-item>

        <!-- Choose whether the image should be displayed -->
        <ion-item *ngIf="block.showImage == false || block.showImage == true">
          <ion-toggle [(ngModel)]="block.showImage" [checked]="block.showImage">Show Image?</ion-toggle>
        </ion-item>
        <ion-item *ngIf="block.mandatory == false || block.mandatory == true">
          <ion-toggle [(ngModel)]="block.mandatory" [checked]="block.mandatory">Default</ion-toggle>
        </ion-item>
        <ion-item (click)="toggleTarget()">
          <ion-label>Target</ion-label>
          <ion-icon slot="end" name="arrow-forward"></ion-icon>
        </ion-item>
        <ion-item>
          <ion-toggle [(ngModel)]="block.bgEnabled" [checked]="block.bgEnabled">Background Colour Enabled</ion-toggle>
        </ion-item>
        <ion-item>
            <ion-input [(ngModel)]="block.backgroundColour" type="text" [value]="block.backgroundColour" placeholder="Background Colour" label="Background Colour" labelPlacement="floating"></ion-input>
        </ion-item>
      </ion-list>
      <ion-button (click)="save()" expand="block" fill="clear">
        <ion-icon slot="start" name="cloud-upload"></ion-icon>
        <ion-label slot="end">Save</ion-label>
      </ion-button>
    </ion-col>
  </ion-row>
</ion-content>