import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingProvider {

  private currentLoader: HTMLIonLoadingElement;

  constructor(
    private loadingController: LoadingController
  ) {
  }

  async present(message?: string) {
    if (!message) {
      message = 'Please wait';
    }
    const loader = await this.loadingController.getTop();
    if (!loader) {
      const loading = await this.loadingController.create({ message: message });
      return loading.present();
    }
  }

  async dismiss() {
    const loader = await this.loadingController.getTop();
    if (loader) {
      await this.loadingController.dismiss();
    }
  }

  async update(message: string, customClass?: string) {
    const loader = await this.loadingController.getTop();
    if (loader) {
      loader.message = message;
      loader.cssClass = customClass;
    }
  }

  async presentWithCancel(message?: string, customClass?: string) {
    if (!message) {
      message = 'Please wait';
    }

    const loader = await this.loadingController.getTop();
    if (!loader) {
      let loading = await this.loadingController.create({
        message: message,
        backdropDismiss: true,
        cssClass: customClass
      });

      this.currentLoader = loading;
      return loading.present();
    }
  }

  getLoader() {
    return this.currentLoader;
  }
}
