import { NgModule } from '@angular/core';

import { CustomerPageModule } from './customer/customer.module';
import { BrowsePageModule } from './browse/browse.module';
import { CartPageModule } from './cart/cart.module';
import { ItemPageModule } from './item/item.module';
import { BlockPageModule } from './block/block.module';
import { AuthPageModule } from './auth/auth.module';
import { ReturnPageModule } from './return/return.module';
import { DiscountPageModule } from './discount/discount.module';
import { TimeoutPageModule } from './timeout/timeout.module';
import { SavedCartPageModule } from './saved-cart/saved-cart.module';

@NgModule({
    imports: [
        CustomerPageModule,
        BrowsePageModule,
        CartPageModule,
        ItemPageModule,
        BlockPageModule,
        AuthPageModule,
        ReturnPageModule,
        DiscountPageModule,
        TimeoutPageModule,
        SavedCartPageModule
    ],
    exports: [
        CustomerPageModule,
        BrowsePageModule,
        CartPageModule,
        ItemPageModule,
        BlockPageModule,
        AuthPageModule,
        ReturnPageModule,
        DiscountPageModule,
        TimeoutPageModule,
        SavedCartPageModule
    ]
})
export class ModalsModule {}
