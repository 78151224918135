import { Component, OnInit, Input } from '@angular/core';
import * as Parse from 'parse';
import { OrderProvider } from '../../providers/order.provider';
import { UriProvider } from '../../providers/uri.provider';
import { CurrencyProvider } from '../../providers/currency.provider';
import { ReturnItem } from '../../interfaces/returnItem';
import { ModalController, NavController } from '@ionic/angular';
import { ReturnProvider } from '../../providers/return.provider';
import { ToastProvider } from '../../providers/toast.provider';

@Component({
  selector: 'app-return',
  templateUrl: './return.page.html',
  styleUrls: ['./return.page.scss'],
})

export class ReturnPage implements OnInit {

  @Input() order: Parse.Object;

  public ready = false;
  public returnItems: ReturnItem[] = [];
  public smallDevice = false;
  public objectKeys = Object.keys;
  private totalReturnQty = 0;
  private prevItems: Object = {};

  constructor(
    private orderProvider: OrderProvider,
    private uriProvider: UriProvider,
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private returnProvider: ReturnProvider,
    public currencyProvider: CurrencyProvider,
    public toastProvider: ToastProvider
  ) { }

  ngOnInit() {
    this.init();
  }

  init() {
    this.setSmallDevice();
    this.getOrderItems(this.order);
  }

  setSmallDevice() {
    this.smallDevice = (this.getWindowWidth() < 768);
  }

  getWindowWidth() {
    const windowWidth: number = window.innerWidth;

    return windowWidth;
  }

  convertPrice(input: string) {
    const result: number = parseFloat(input);

    return this.currencyProvider.transform(result);
  }

  async getOrderItems(
    order: Parse.Object
  ) {
    let items: Parse.Object[] | false = false;
    items = await this.orderProvider.getItems(order.id);

    if (items) {
      await this.getPreviousReturns();

      for (const item of items) {
        let available = item.get('qty');

        if (item.id in this.prevItems) {
          available -= this.prevItems[item.id];
        }

        const returnItem: ReturnItem = {
          ordered: item,
          returnQty: 0,
          maxReturnQty: available,
          availableReturnQty: available
        };

        this.returnItems.push(returnItem);
      }

      this.ready = true;
    }
  }

  async continue() {
    if (this.totalReturnQty === 0) {
      this.toastProvider.temporary('Please add items to return');

      return;
    }

    this.returnProvider.setCurrentReturn(this.order, this.returnItems);
    this.progressToReturn();
  }

  progressToReturn() {
    this.closeReturnModal();
    this.navigateTo('return');
  }

  navigateTo(route: string) {
    this.navCtrl.navigateRoot(route);
  }

  getImageUrl(
    item: Parse.Object
  ): string {
    if (item) {
      const image = item.get('image');

      if (image) {
        return this.uriProvider.forceHttps(image);
      }
    }

    return '';
  }

  incrementQty(
    item: ReturnItem
  ) {
    const returnAtMax = (item.returnQty === item.maxReturnQty);

    if (!returnAtMax) {
      this.totalReturnQty++;
      item.returnQty++;
      item.availableReturnQty--;
    }
  }

  decrementQty(
    item: ReturnItem
  ) {
    const returnAtMin = (item.returnQty === 0);

    if (!returnAtMin) {
      this.totalReturnQty--;
      item.returnQty--;
      item.availableReturnQty++;
    }
  }

  closeReturnModal() {
    this.modalCtrl.dismiss();
  }

  async getPreviousReturns() {
    const refunds = await this.returnProvider.getForOrder(this.order.id);

    if (refunds.length) {
      for (const refund of refunds) {
        const refundItems = refund.get('items');

        for (const item of refundItems) {
          if (item.ordered.id in this.prevItems) {
            this.prevItems[item.ordered.id] += item.returnQty;
          } else {
            this.prevItems[item.ordered.id] = item.returnQty;
          }
        }
      }
    }
  }
}
