import { Injectable } from '@angular/core';
import { User } from '../interfaces/user';
import { ParseProvider } from './parse.provider';
import { AlertController } from '@ionic/angular';
import { SessionProvider } from './session.provider';
import { UserSessionProvider } from './session/user-session.provider';
import { ToastProvider } from './toast.provider';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationProvider {

  public currentUsers: any[];
  public elevatedUser: boolean = false;

  constructor(
    private parseProvider: ParseProvider,
    private alertCtrl: AlertController,
    private sessionProvider: SessionProvider,
    public userSessionProvider: UserSessionProvider,
    private toastProvider: ToastProvider
  ) {
  }

  isLoggedIn(): boolean {
    return this.sessionProvider.user.isLoggedIn();
  }

  async login(
    user: User,
    type: string
  ): Promise<boolean> {
    const currentUser: Parse.User|false = await this.getCurrentUser();
    let token: string|null = null;
    const elevated = await this.elevate(user);
    this.elevatedUser = elevated;

    if (type == 'elevate') {
      if (
        currentUser
        && currentUser.getUsername() === user.username
      ) {
        this.toastProvider.temporary('Tried to elevate with current logged in user');

        return;
      }

      if (currentUser && this.isLoggedIn()) {
        token = currentUser.get('sessionToken');
      }

      if (elevated) {
        await this.parseProvider.user.login(user.username, user.password, token, type);
      } else {
        this.toastProvider.temporary('User does not provide additional permissions');
        await this.parseProvider.user.login(user.username, user.password, token, 'notElevated');
      }
    } else if (currentUser && currentUser.getUsername() === user.username && currentUser.get('sessionToken')) {
      const result = await this.parseProvider.user.verifyPassword(user.username, user.password);

      if (!result) {
        return false;
      }

      await this.parseProvider.user.login(user.username, user.password, currentUser.getSessionToken(), type);
    } else {
      await this.parseProvider.user.login(user.username, user.password, null, type);
    }

    return true;
  }

  async elevate(
    user: User
  ): Promise<boolean> {
    const elevatedUser = await this.parseProvider.user.getUser(user.username);
    let result = false;

    if (elevatedUser) {
      result = elevatedUser.get('manager');
    } else {
      console.log('elevation denied');
    }

    return result;
  }

  logout() {
    this.parseProvider.user.logout();
  }

  async resetPassword() {
    const alert = await this.alertCtrl.create({
      header: 'Reset Password',
      inputs: [
        {
          name: 'email',
          type: 'email',
          placeholder: 'Email'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {}
        },
        {
          text: 'Ok',
          handler: data => this.parseProvider.user.resetPassword(data.email)
        }
      ]
    });

    await alert.present();
  }

  async logoutUser() {
    await this.parseProvider.user.logout();
    await this.userSessionProvider.onLogout();
  }

  async getCurrentUser() {
    return await this.parseProvider.user.getCurrentUser();
  }
}
