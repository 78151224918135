import { Injectable } from '@angular/core';
import { DeviceInfo, Device } from '@capacitor/device';
import { ParseProvider } from './parse.provider';
import { ToastProvider } from './toast.provider';
import { AlertController } from '@ionic/angular';
import { LoadingProvider } from './loading.provider';
import * as Parse from 'parse';

@Injectable({
  providedIn: 'root'
})
export class DeviceProvider {

  private setLabelAlert = false;

  constructor(
    public parseProvider: ParseProvider,
    private toastProvider: ToastProvider,
    private alertController: AlertController,
    private loadingProvider: LoadingProvider
  ) {
  }

  async device(): Promise<DeviceInfo> {
    return await Device.getInfo();
  }

  async getDeviceId() {
    return await Device.getId();
  }

  async get(
    uuid: string
  ): Promise<Parse.Object> {
    let device: Parse.Object;
    let label: string|false = false;
    const deviceInfo: DeviceInfo = await this.device();

    try {
      device = await this.parseProvider.device.get(uuid);
    } catch(e) {
      // if we catch an error from parse, either allow the user to retry 
      // (if it's a connection problem [code 100]), otherwise force logout
      (e.code === 100) ? this.retryAlert(uuid) : this.logout();
    }

    device = (device) ? device : await this.set(deviceInfo);
    label = (device) ? await this.checkLabel(device) : null;
    device = (label && device) ? await this.setLabel(device, label) : device;

    return device;
  }

  async retryAlert(
    uuid: string
  ) {
    await this.loadingProvider.dismiss();
    const alert = await this.alertController.create({
      backdropDismiss: false,
      header: 'Could not connect',
      message: 'Device could not connect to the server',
      buttons: [
        { text: 'Retry', handler: () => this.get(uuid)},
        { text: 'Logout', handler: () => this.logout()}
      ]
    });

    await alert.present();
  }

  async logout() {
    this.parseProvider.user.logout();
  }

  async checkLabel(
    device: Parse.Object
  ): Promise<string|false> {
    let result: string|false = false;
    const label = await device.get('label');

    if (!label) {
      result = await this.createDeviceLabelAlert(device);
    }

    return result;
  }

  async createDeviceLabelAlert(
    device: Parse.Object
  ): Promise<string|false> {
    let result: string|false = false;
    const alert = await this.alertController.create({
      backdropDismiss: false,
      header: 'Set Device Name',
      message: 'New device detected, please set a device name',
      inputs: [
        {name: 'label', type: 'text', placeholder: 'Device name'}
      ],
      buttons: [
        {
          text: 'OK',
          handler: data => {}
        }
      ]
    });

    if (!this.setLabelAlert) {
      this.setLabelAlert = true;
      await alert.present();
      const alertResult = await alert.onDidDismiss();
      this.setLabelAlert = false;
      const label = alertResult.data.values.label;

      if (label == "") {
        this.toastProvider.temporary('Name cannot be empty');
        this.createDeviceLabelAlert(device);
      } else {
        result = alertResult.data.values.label;
      }
    }
    return result;
  }

  async setLabel(
    device: Parse.Object,
    label: string
  ): Promise<Parse.Object> {
    device.set('label', label);

    return await device.save();
  }

  async set(
    deviceInfo: DeviceInfo
  ): Promise<Parse.Object> {
    return await this.parseProvider.device.set(deviceInfo);
  }

  async drawer(
    drawer: Parse.Object
  ): Promise<Parse.Object> {
    const device = await this.getCurrent('device provider :: drawer');

    return await this.parseProvider.device.drawer(device, drawer);
  }

  unsetDrawer(device: Parse.Object) {
    return this.parseProvider.device.unsetDrawer(device);
  }

  unsetDrawers(drawer: Parse.Object) {
    return this.parseProvider.device.unsetDrawers(drawer);
  }

  async getCurrent(
    message?: string
  ): Promise<Parse.Object> {
    (message) ? console.log('getCurrent from ', message) : null;
    const unauthenticated = await this.parseProvider.user.getCurrentUser() ? false : true;

    return await this.parseProvider.device.getCurrent(unauthenticated);
  }
  
  async getFromPointerArray(
    devicePointerArray: Parse.Pointer[]
  ): Promise<Parse.Object[]> {
    return await this.parseProvider.device.getFromPointerArray(devicePointerArray);
  }
}
