<ion-header class="item item-header">
  <ion-toolbar class="no-safe-area">
    <ion-title>{{item.name}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">Done</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content [fullscreen]="true" class="discount-modal">
  <ion-list>
    <ion-item>
      <ion-label>
        <ion-row>
          <ion-col>
            <h2>Original Price</h2>
            <h1 *ngIf="!priceEdited">
              <span [innerHtml]="formatPrice(item.price)"></span>
            </h1>
            <h1 *ngIf="priceEdited" class="price-edited">
              <span [innerHtml]="formatPrice(item.originalPrice)"></span>
            </h1>
          </ion-col>
          <ion-col *ngIf="priceEdited">
            <h2>Discount</h2>
            <h1>
              <span [innerHtml]="formatPrice(this.getDifference(item.originalPrice, item.price))"></span>
            </h1>
          </ion-col>
          <ion-col *ngIf="priceEdited">
            <h2>% Discount</h2>
            <h1><span [innerHtml]="formatPrice(this.getPercent(item.originalPrice, item.price), false)"></span></h1>
          </ion-col>
        </ion-row>
      </ion-label>
      <ion-button *ngIf="priceEdited" (click)="resetPrice()" color="danger" fill="outline" size="small">
        <ion-label>Reset</ion-label>
      </ion-button>
    </ion-item>
    <ion-item>
      <ion-button (click)="openKeypad('price')" color="primary" fill="solid">
        <ion-label>Edit Price</ion-label>
      </ion-button>
      <ion-button (click)="openKeypad('discount')" color="primary" fill="outline">
        <ion-label>Edit Discount</ion-label>
      </ion-button>
      <ion-button (click)="openKeypad('percentage')" color="primary" fill="outline">
        <ion-label>Edit Discount %</ion-label>
      </ion-button>
    </ion-item>
    <ion-item>
      <ion-label>
        <ion-row>
          <ion-col *ngIf="priceEdited">
            <h2>Edited Price</h2>
            <h1><span [innerHtml]="formatPrice(item.price)"></span></h1>
          </ion-col>
          <ion-col>
            <h2>Quantity in cart</h2>
            <h1 [innerHtml]="item.qty"></h1>
          </ion-col>
          <ion-col>
            <h2>Row Total</h2>
            <h1><span [innerHtml]="formatPrice(item.rowTotal)"></span></h1>
          </ion-col>
        </ion-row>
      </ion-label>
    </ion-item>
    <keypad *ngIf="keypadVisible" [keypadValue]="keypadValue" (change)="updateValueFromKeypad($event)"></keypad>
  </ion-list>

</ion-content>

<ion-footer class="tab-footer">
  
</ion-footer>