import { Injectable } from '@angular/core';
import { ParseProvider } from '../parse.provider';
import { Message } from '../../interfaces/message';
import { NavController, ModalController, AlertController } from '@ionic/angular';
import * as Parse from 'parse';
import { ToastProvider } from '../toast.provider';
import { EventProvider } from '../event.provider';
import { DeviceProvider } from '../device.provider';

@Injectable({
  providedIn: 'root'
})
export class UserSessionProvider {

  private authenticated = false;
  public user: Parse.User;
  public activeUser = false;
  public elevated = false;

  constructor(
    private parseProvider: ParseProvider,
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    private alertController: AlertController,
    private toastProvider: ToastProvider,
    private eventProvider: EventProvider,
    private deviceProvider: DeviceProvider
  ) {
    this.initialiseSubscriptions();
  }

  isLoggedIn() {
    return this.authenticated;
  }

  async getCurrentUser(): Promise<false|Parse.User> {
    if (this.authenticated) {
      return await this.parseProvider.user.getCurrentUser();
    }
    return false;
  }

  initialiseSubscriptions() {
    this.parseProvider.user.messages.subscribe(message => this.parseSubscriber(message));
  }

  parseSubscriber(message: Message) {
    switch (message.type) {
      case 'login':
        this.onLogin(message.data);
        break;
      case 'logout':
        this.onLogout(message);
        break;
      case 'resetPassword':
        this.onResetPassword(message);
        break;
      case 'timeout':
        this.onTimeout(message.data);
        break;
      case 'elevate':
        this.onElevate(message);
        break;
      case 'notElevated':
        this.onNotElevated(message);
        break;
      default:
        this.onError(message);
        break;
    }
  }

  async onLogin(
    user: Parse.User
  ) {
    const deviceId = await this.deviceProvider.getDeviceId();
    // this be the only place in the app that calls the deviceProvider.get 
    // function (and in turn the deviceProvider.set function). We only want
    // to add new devices to parse once the user has authenticated
    await this.deviceProvider.get(deviceId.identifier);
    this.clearModal();
    this.authenticated = true;
    this.user = user;
    this.navCtrl.navigateRoot('/');
    this.activeUser = true;
    this.firstTimeCheck();
    this.eventProvider.create('user_session-login');
  }

  async firstTimeCheck() {
    let firstTime = false;
    const user = await this.getCurrentUser();
    if (user) {
      firstTime = await user.get('firstTime');
    }
    if (user && firstTime) {
      this.passwordUpdateAlert(user);
    }
  }

  async passwordUpdateAlert(
    user: Parse.User
  ) {
    const alert = await this.alertController.create({
      backdropDismiss: false,
      header: 'Update password',
      message: 'First time login detected, please choose a new password',
      inputs: [
        { name: 'password', type: 'password', placeholder: 'Password' },
        { name: 'passwordConfirm', type: 'password', placeholder: 'Confirm Password' }
      ],
      buttons: [
        { text: 'Update password', handler: data => 
          { this.confirmNewPassword(data, user) }
        }
      ]
    });
    alert.present();
  }

  async confirmNewPassword(
    data: any, 
    user: Parse.User
  ) {
    const match = ((data.password === data.passwordConfirm) && data.password);
    if (!match) {
      this.passwordUpdateAlert(user);
      await this.toastProvider.temporary('Passwords do not match');
      return;
    }
    const result = await this.updatePassword(data.password, user);
    if (result) {
      await this.toastProvider.temporary('Password updated successfully');
      this.removeFirstTimeFlag(user);
    } else {
      this.passwordUpdateAlert(user);
    }
  }

  async updatePassword(
    password: string,
    user: Parse.User
  ) {
    let result: false|Parse.User = false;
    user.set('password', password);
    try {
      result = await user.save();
    } catch(e) {
      this.toastProvider.temporary(e.message)
    }
    return result;
  }

  async removeFirstTimeFlag(
    user: Parse.User
  ) {
    user.set('firstTime', false);
    try {
      await user.save();
    } catch(e) {
      console.log(e);
      this.toastProvider.temporary('There was an error connecting to the server, you may need to reset the password on next login');
    }
  }

  async clearModal() {
    console.log('User session provider :: clearModal');
    const modal = await this.modalCtrl.getTop();
    if (modal) {
      modal.dismiss();
    }
  }

  onLogout(message?: Message) {
    this.authenticated = false;
    this.user = null;
    this.navCtrl.navigateRoot('/login');
    this.activeUser = false;
    this.clearModal();
  }

  async onBecome(user: Parse.User) {
    this.user = user;
  }

  async onTimeout(user: Parse.User) {
    console.log('User session provider :: onTimeout');
    this.clearModal();
  }

  onResetPassword(message) {
    // TODO :: Alert user
    console.log('Password reset request sent!', message);
  }

  onError(message) {
    // TODO :: Alert user
    if (message.error) {
      console.log(message.error);
    } else {
      console.log('AuthenticationProvider :: Something went wrong', message);
    }
  }

  async onElevate(message) {
    const user = Parse.User.current();
    if (user) {
      const username = await user.get('username');
      console.log('elevating using the user ->', username);
    }
    await this.resetUser(message.data.token);
    this.elevated = true;
    this.clearModal();
  }

  async onNotElevated(message) {
    console.log('not elevated');
    await this.resetUser(message.data.token);
    this.elevated = false;
  }

  async resetUser(token) {
    Parse.User.logOut();
    const user = await this.parseProvider.user.become(token);
    if (user) {
      const username = user.get('username');
      console.log('reset to orginal user ->', username);
    }
    return;
  }
}
