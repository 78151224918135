import { Injectable } from '@angular/core';
import * as Parse from 'parse';

@Injectable({
  providedIn: 'root'
})

export class ParseTradingProvider {

  async total(
    start: Date,
    end: Date,
    drawer: Parse.Object
  ) {
    let query = new Parse.Query('OrderPayment');

    let total: any = {
      cash: 0,
      cashCount: 0,
      card: 0,
      cardCount: 0,
      voucher: 0,
      voucherCount: 0,
      account: 0,
      accountCount: 0,
      payments: 0,
      noSales: {}
    }

    total.noSales = await this.getNoSalesTotal(start, end, drawer);
    total.refunds = await this.getRefundTotals(start, end, drawer);

    query.lessThanOrEqualTo('updatedAt', end);
    query.greaterThanOrEqualTo('updatedAt', start);
    //TODO: add drawers to payments in checkout
    query.equalTo('drawer', drawer);
    query.limit(1000);
    let result = query.find();
    return await result.then(payments => {
      payments.forEach(payment => {
        let amount = payment.get('amount');
        switch (payment.get('type')) {
          case 'cash':
            total.cash += amount;
            total.payments += 1;
            total.cashCount += 1;
            break;
          case 'card':
            total.card += amount;
            total.payments += 1;
            total.cardCount += 1;
            break;
          case 'voucher':
            total.voucher += amount;
            total.payments += 1;
            total.voucherCount += 1;
            break;
          case 'account':
            total.account += amount;
            total.payments += 1;
            total.accountCount += 1;
            break;
        }
      })
      return total;
    })

  }

  async noSale(
    user: Parse.User,
    drawer: Parse.Object | boolean
  ) {
    const NoSale = Parse.Object.extend('NoSale');
    const noSale = new NoSale();

    noSale.set('user', user);
    noSale.set('drawer', drawer);

    return await noSale.save();
  }

  async getNoSalesTotal(start: Date, end: Date, drawer: Parse.Object): Promise<Object> {
    let noSaleQuery = new Parse.Query('NoSale');

    noSaleQuery.lessThanOrEqualTo('updatedAt', end);
    noSaleQuery.greaterThanOrEqualTo('updatedAt', start);

    noSaleQuery.equalTo('drawer', drawer);
    noSaleQuery.limit(1000);
    const noSaleResults = await noSaleQuery.find();

    let noSaleObject = {};
    for (let i = 0; i < noSaleResults.length; i++) {
      const noSaleResult = noSaleResults[i]
      const user = await noSaleResult.get('user');
      const username = await user.get('username');
      noSaleObject[username] = noSaleObject[username] ? noSaleObject[username] + 1 : 1;
    }

    return noSaleObject;
  }

  async getRefundTotals(start: Date, end: Date, drawer: Parse.Object): Promise<Object> {
    const query = new Parse.Query('ReturnOrder');
    query.lessThanOrEqualTo('updatedAt', end);
    query.greaterThanOrEqualTo('updatedAt', start);
    query.equalTo('drawer', drawer);
    query.limit(1000);
    const result = await query.find();

    let refunds = {
      cash: 0,
      card: 0,
      voucher: 0,
      account: 0,
      payments: 0
    }

    for (const refund of result) {
      for (const refundPayment of refund.get('payments')) {
        let amount = refundPayment.amount
        switch (refundPayment.method) {
          case 'cash':
            refunds.cash += amount;
            refunds.payments += 1;
            break;
          case 'card':
            refunds.card += amount;
            refunds.payments += 1;
            break;
          case 'voucher':
            refunds.voucher += amount;
            refunds.payments += 1;
            break;
          case 'account':
            refunds.account += amount;
            refunds.payments += 1;
            break;
        }
      }
    }

    return refunds;
  }

  async startSession(
    drawer: Parse.Object,
    device: Parse.Object,
    user: Parse.User
  ): Promise<Parse.Object> {
    const TradingSession = Parse.Object.extend('TradingSession');
    const tradingSession = new TradingSession();
    tradingSession.set('drawer', drawer);
    tradingSession.set('deviceStart', device);
    tradingSession.set('userStart', user);
    const username = await user.get('username');
    tradingSession.set('usernameStart', username);
    const devices: Parse.Object[] = [];
    devices.push(device);
    tradingSession.set('devices', devices);
    const users: Parse.User[] = [];
    users.push(user);
    tradingSession.set('users', users);
    let result = await tradingSession.save();
    result = await this.touchSession(result, device, user);
    return result;
  }

  async touchSession(
    tradingSession: Parse.Object,
    device: Parse.Object,
    user: Parse.User
  ): Promise<Parse.Object> {
    tradingSession.addUnique('users', user);
    const username = await user.get('username');
    tradingSession.addUnique('usernames', username);
    tradingSession.addUnique('devices', device);
    const result = await tradingSession.save();
    return result;
  }

  async endSession(
    tradingSession: Parse.Object,
    device: Parse.Object,
    user: Parse.User
  ): Promise<Parse.Object> {
    tradingSession = await this.touchSession(tradingSession, device, user);
    tradingSession.set('deviceEnd', device);
    tradingSession.set('userEnd', user);
    const username = await user.get('username');
    tradingSession.set('usernameEnd', username);
    const now = new Date();
    tradingSession.set('endDate', now);
    const result = await tradingSession.save();
    return result;
  }

  async saveReport(
    tradingSession: Parse.Object,
    report: Parse.Object
  ): Promise<Parse.Object> {
    tradingSession.set('report', report);
    const result = await tradingSession.save();
    return result;
  }

  async getFromId(
    id: string
  ): Promise<Parse.Object> {
    const query = new Parse.Query('TradingSession');
    query.include('devices');
    return await query.get(id);
  }

}
