import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ParseProvider } from '../providers/parse.provider';

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  public updates: BehaviorSubject<any> = new BehaviorSubject(undefined);

  constructor(
    private parseProvider: ParseProvider
  ) {
  }

  public async refresh():Promise<boolean> {
    const statuses = {
      orderExport: await this.getOrderExportStatus(),
      receiptGeneration: await this.getReceiptStatus()
    }

    let errorCount = 0;

    Object.values(statuses).forEach(status => {
      if (!status) {
        errorCount++;
      }
    })

    this.updates.next({
      errorCount,
      statuses
    });
    return true;
  }

  public async getUnexportedOrders() {
    return this.parseProvider.order.getUnexportedOrders();
  }

  public async getOrdersWithoutReceipt() {
    return this.parseProvider.order.getOrdersWithoutReceipt();
  }

  private async getOrderExportStatus() {
    const count = await this.parseProvider.order.getUnexportedOrdersCount();

    return (count)
      ? false
      : true;
  }

  private async getReceiptStatus() {
    const count = await this.parseProvider.order.getOrdersWithoutReceiptCount();

    return (count)
      ? false
      : true;
  }

}
