import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ParseSearchProvider {

  public resultsUpdated: Subject<Parse.Object[]> = new Subject();

  async fullText(
    columns: string[],
    searchTerms: string[],
    listIds?: string[] | null | false,
    sortField?: string,
    sortDescending?: boolean,
  ) {
    const promises = searchTerms.map(async term => {
      const escapedTerm = term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const pattern =`.*${escapedTerm}.*`;
      const regEx = new RegExp(pattern);
      const queries = [];

      columns.forEach(column => {
        const itemQuery = new Parse.Query('Item');
        itemQuery.matches(column, regEx, 'i')
          .containedIn('visibility', ['3', '4'])
          .limit(600);

        if (listIds && listIds.length) {
          const listQuery = new Parse.Query('List');
          listQuery.containedIn('objectId', listIds);
          itemQuery.matchesQuery('lists', listQuery);
        }

        queries.push(itemQuery);
      });
      const query = Parse.Query.or(...queries);

      if (sortField) {
        if (sortDescending) {
          query.addDescending(sortField);
        } else {
          query.addAscending(sortField);
        }
      }

      return query.find();
    });

    const resultArray = await Promise.all(promises);
    let results = [];
    resultArray.forEach(result => {
      results = results.concat(result);
    });
    this.resultsUpdated.next(results);
  }

}
