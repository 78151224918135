import { Injectable } from '@angular/core';
import * as Parse from 'parse';

@Injectable({
  providedIn: 'root'
})
export class ParseLayoutProvider {


  async get(
    device: Parse.Object,
    orientation: string
  ) {
    const query = new Parse.Query('Layout');
    query.equalTo('device', device);
    query.equalTo('orientation', orientation);
    return await query.first();
  }

  async create(
    device: Parse.Object,
    orientation: string
  ) {
    const Layout = Parse.Object.extend('Layout');
    const layout = new Layout();
    layout.set('device', device);
    layout.set('orientation', orientation);
    let result: Parse.Object | false = false;
    try {
      result = await layout.save();
    } catch (error) {
      console.log('create layout error');
    }
    return result;
  }

}
