import { Injectable } from '@angular/core';
import * as Parse from 'parse';

@Injectable({
  providedIn: 'root'
})

export class ParseReportProvider {

  async set(
    reportArray: string[],
    type: string,
    options?: any
  ): Promise<Parse.Object> {
    const Report = Parse.Object.extend('Report');
    const report = new Report();

    report.set('report', reportArray);
    report.set('type', type);

    if (options) {
      report.set('options', options);
    }

    return await report.save();
  }

  async all(type: string): Promise<Parse.Object[]> {
    const query = new Parse.Query('Report');
    query.equalTo('type', type);
    query.descending('createdAt');

    return await query.find();
  }

  async getPaginatedReports(
    type: string,
    start: number,
    pageSize: number,
    drawer?: string
  ): Promise<Parse.Object[]> {
    const query = new Parse.Query('Report');
    query.descending('createdAt');
    query.equalTo('type', type);
    query.skip(start);
    query.limit(pageSize);

    if (drawer) {
      query.equalTo('options.drawer', drawer)
    }

    return await query.find();
  }

}
