import { Injectable } from '@angular/core';
import { StarPRNT, Printer, CommandsArray, PrintCommand, Printers } from '@awesome-cordova-plugins/star-prnt/ngx';
import { PrintProvider } from '../providers/print.provider';
import { Observable } from 'rxjs';
import { LoadingController } from '@ionic/angular';
import { ToastProvider } from './toast.provider';

@Injectable({
  providedIn: 'root'
})
export class PrinterProvider {

  public printer: Printer;
  public printingType = 'browser';

  constructor(
    private starprnt: StarPRNT,
    private printProvider: PrintProvider,
    private loadingController: LoadingController,
    private toastProvider: ToastProvider
  ) {

  }

  async getPrinterList(): Promise<Printers> {
    const printers = await this.starprnt.portDiscovery('Bluetooth');
    return printers;
  }

  setPrinter(printer: Printer) {
    this.printer = printer;
  }

  unsetPrinter() {
    this.printer = {};
    this.starprnt.disconnect();
  }

  getPrinter(): Printer {
    return this.printer;
  }

  getAlign(type: string): string {
    let align;
    if (type === 'left') {
      align = this.starprnt.AlignmentPosition.Left;
    } else if (type === 'center') {
      align = this.starprnt.AlignmentPosition.Center;
    } else if (type === 'right') {
      align = this.starprnt.AlignmentPosition.Right;
    }
    return align;
  }

  getBarcodeCommand(
    text: string,
    height: number,
    hri: boolean
  ): PrintCommand {
    return {
      appendBarcode: text,
      BarcodeSymbology: this.starprnt.BarcodeSymbology.Code128,
      BarcodeWidth: this.starprnt.BarcodeWidth.Mode1,
      height: height,
      hri: hri
    };
  }

  print(commands: CommandsArray) {
    switch (this.printingType) {
      case 'browser':
        this.printFromBrowser(commands);
        break;
      case 'star':
        this.starPRNT(commands);
        break
    }
  }

  starPRNT(
    commands: CommandsArray
  ) {
    if (this.printer) {
      this.starprnt.print(null, 'StarPRNT', commands);
    } else {
      console.log('no printer set up');
      // TODO :: Alert/Action sheet
    }
  }

  async connect() {
    if (this.printer) {
      const loading = await this.loadingController.create({
        message: 'Connecting to printer'
      });
      loading.present();
      console.log('connecting to printer', this.printer.portName);
      this.starprnt.connect(this.printer.portName, 'StarPRNT', false)
        .subscribe(result => {
          this.loadingController.dismiss();
          console.log(result);
          this.toastProvider.temporary(this.printer.modelName + '  set succesfully');
        }, error => {
          this.loadingController.dismiss();
          console.log(error);
          this.printer = {};
          this.toastProvider.temporary('Unable to connect');
        })
    }
  }

  getStatus(): Observable<any> {
    return this.starprnt.getStatus();
  }

  printFromBrowser(commands: CommandsArray) {
    this.convertToStringArray(commands);
  }

  convertToStringArray(
    commands: CommandsArray
  ) {
    let resultArray = [];

    for (let i = 0; i < commands.length; i++) {
      const command = commands[i];
      let pushString = '';
      let alignment: string = null;

      if (command.append) {
        pushString = this.style(command.append, alignment);
      } else if (command.appendUnderline) {
        pushString = this.style(command.appendUnderline, alignment);
      } else if (command.appendEmphasis) {
        pushString = this.style(command.appendEmphasis, alignment);
      } else if (command.appendInvert) {
        pushString = this.style(command.appendInvert, alignment);
      } else if (command.appendMultiple) {
        pushString = this.style(command.appendMultiple, alignment);
      } else if (command.appendAlignment) {
        console.log('alignment');
        alignment = command.appendAlignment;
      }

      resultArray.push(pushString);
    }

    this.printProvider.sendDataToPrinter(resultArray);
  }

  style(
    input: string,
    alignment?: string,
    format?: string
  ) {
    let result = '';
    result = '<pre>' + this.truncate(input) + '</pre>';
    if (alignment) {
      result = '<span class="' + alignment + '">' + input + '</span>';
    }
    return result;
  }

  truncate(
    input: string
  ) {
    const length = input.length;
    const max = 34;
    let result = '';
    if (length > max) {
      result = this.addEllipsis(this.trim(input, max - 3));
    } else {
      result = input;
    }
    return result;
  }

  addEllipsis(
    input: string
  ) {
    return input + '...';
  }

  trim(
    input: string,
    trimAmount: number
  ) {
    return input.substring(0, trimAmount);
  }

}
