import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { CartItemCollection } from '../../interfaces/cartItemCollection';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ParseOrderProvider {
  public resultsUpdated: Subject<Parse.Object[]> = new Subject();

  get(id: string): Promise<Parse.Object> {
    const query = new Parse.Query('Order');

    return query.get(id);
  }

  async getAll(customer?: Parse.Object) {
    const query = new Parse.Query('Order');
    query.limit(1000);
    query.descending('createdAt');
    query.equalTo('complete', true);

    if (customer) {
      query.equalTo('customer', customer);
    }

    const orders: Parse.Object[] = await query.find();

    return orders;
  }

  async getPaginatedOrders(
    start: number,
    size: number,
    customer?: Parse.Object,
    location?: String,
    from?: Date,
    to?: Date
  ) {
    const query = new Parse.Query('Order');
    query.descending('createdAt');
    query.equalTo('complete', true);
    query.skip(start);
    query.limit(size);

    if (customer) {
      query.equalTo('customer', customer);
    }

    if (location) {
      query.equalTo('location', location)
    }

    if (from) {
      query.greaterThanOrEqualTo('createdAt', from);
    }

    if (to) {
      query.lessThanOrEqualTo('createdAt', to);
    }

    const orders: Parse.Object[] = await query.find();

    return orders;
  }

  async getItems(id: string): Promise<Parse.Object[]> {
    const innerQuery = new Parse.Query('Order');
    innerQuery.equalTo('objectId', id);
    const query = new Parse.Query('OrderItem');
    query.matchesQuery('order', innerQuery);
    query.include('item');

    return await query.find();
  }

  async create(details: any = {}): Promise<Parse.Object> {
    return Parse.Cloud.run('backend-order::create', {
      details: details
    });
  }

  async complete(order: Parse.Object): Promise<string> {
    const result = await Parse.Cloud.run('backend-order::complete', {
      orderId: order.id,
      emailNotify: order['emailNotify'],
      total: order['total']
    });

    return result;
  }

  async finalise(order: Parse.Object, debug: number): Promise<Parse.Object> {
    return await Parse.Cloud.run('backend-order::finalise', {
      orderId: order.id,
      debug: debug
    });
  }

  /**
   * @param details 
   * @param drawer 
   * @param voucher 
   */
  // TODO :: Use cloud code function
  async addPayment(details, drawer?: Parse.Object, voucher?: string): Promise<Parse.Object> {
    const Payment = Parse.Object.extend('OrderPayment');
    const payment = new Payment();
    payment.set('type', details.type);
    payment.set('order', details.order);
    payment.set('amount', details.amount);

    if (voucher) {
      console.log(`addPayment:: voucher ` + voucher);
      payment.set('voucher', voucher);
    }

    if (drawer) {
      payment.set('drawer', drawer);
    }

    if (details.uuid) {
      payment.set('uuid', details.uuid);
    }

    return await payment.save();
  }

  async getPayments(id: string): Promise<Parse.Object[]> {
    const innerQuery = new Parse.Query('Order');
    innerQuery.equalTo('objectId', id);
    const query = new Parse.Query('OrderPayment');
    query.matchesQuery('order', innerQuery);

    return query.find();
  }

  async addItems(
    orderId: string,
    items: CartItemCollection
  ) {
    const result = await Parse.Cloud.run('backend-order::addItems', {
      orderId: orderId,
      items: this.translateItems(items)
    });

    return result;
  }

  async updateItems(
    orderId: string,
    items: CartItemCollection
  ) {
    const result = await Parse.Cloud.run('backend-order::updateItems', {
      orderId: orderId,
      items: this.translateItems(items)
    });

    return result;
  }

  async setCustomer(
    order: Parse.Object,
    mode: string,
    customer?: Parse.Object
  ): Promise<boolean> {
    const params = {
      orderId: order.id,
      data: {
        mode: mode
      }
    };

    if (customer) {
      params.data['customerId'] = customer.id;
    }

    const result = await Parse.Cloud.run('backend-order::setCustomer', params);

    return result;
  }

  translateItems(items: CartItemCollection): any[] {
    const array = [];

    Object.keys(items).forEach(key => {
      const item = items[key];

      if (item.originalPrice) {
        array.push({
          name: item.name,
          originalPrice: parseFloat(item.originalPrice),
          price: parseFloat(item.price),
          qty: item.qty,
          itemId: item.item.id,
          options: item.options,
          image: item.image,
          taxClass: item.taxClass
        });
      } else {
        array.push({
          name: item.name,
          price: parseFloat(item.price),
          qty: item.qty,
          itemId: item.item.id,
          options: item.options,
          image: item.image,
          taxClass: item.taxClass
        });
      }
    });

    return array;
  }

  cancel(order: Parse.Object) {
    Parse.Cloud.run('backend-order::cancel', {
      orderId: order.id
    });
  }

  async getExternalId(orderId: string) {
    return Parse.Cloud.run('backend-order::getExternalId', {
      id: orderId
    });
  }

  async search(
    searchTerms: string[],
    mode: string,
    location?: string,
    from?: Date,
    to?: Date
  ) {
    const promises = searchTerms.map(async term => {
      const escapedTerm = term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const pattern = `.*\\b${escapedTerm}\\b.*`;
      const regEx = new RegExp(pattern);
      const queries = [];
      let innerQuery = new Parse.Query('Order');
      innerQuery.limit(200);
      innerQuery.equalTo('complete', true);

      if (from) {
        innerQuery.greaterThanOrEqualTo('createdAt', from);
      }

      if (to) {
        innerQuery.lessThanOrEqualTo('createdAt', to);
      }

      switch (mode) {
        case 'customer':
          const first = new Parse.Query('Customer');
          first.matches('firstName', regEx, 'i')
            .limit(200);

          const last = new Parse.Query('Customer');
          last.matches('lastName', regEx, 'i')
            .limit(200);

          const nameQuery = Parse.Query.or(first, last)
          innerQuery.matchesQuery('customer', nameQuery);
          queries.push(innerQuery);

          break;
        case 'objectId':
          innerQuery.startsWith('objectId', term);
          queries.push(innerQuery);

          break;
        case 'total':
          innerQuery.startsWith('total', term);
          queries.push(innerQuery);

          break;
        default:
          innerQuery.equalTo('externalId', parseInt(term))
          queries.push(innerQuery);
      }

      const query = Parse.Query.or(...queries);
      query.descending('createdAt');

      if (location) {
        query.equalTo('location', location);
      }

      return query.find();
    });

    const resultArray = await Promise.all(promises);
    let results = [];

    resultArray.forEach(result => {
      results = results.concat(result);
    });

    this.resultsUpdated.next(results);
  }

  public async getUnexportedOrders() {
    return this.getUnexportedOrdersQuery().find();
  }

  public async getUnexportedOrdersCount() {
    return this.getUnexportedOrdersQuery().count();
  }

  public async getOrdersWithoutReceipt() {
    return this.getOrdersWithoutReceiptQuery().find();
  }

  public async getOrdersWithoutReceiptCount() {
    return this.getOrdersWithoutReceiptQuery().count();
  }

  private getUnexportedOrdersQuery() {
    const query = new Parse.Query('Order');
    query.equalTo('complete', true);
    query.doesNotExist('refunded');
    query.doesNotExist('externalId');

    return query;
  }

  private getOrdersWithoutReceiptQuery() {
    const query = new Parse.Query('Order');
    query.equalTo('complete', true);
    query.doesNotExist('receipt');

    return query;
  }
}
