<ion-item class="keypad">
  <ion-grid class="pad">
    <ion-row>
      <ion-col>
        <ion-button size="large" disabled expand="full" fill="clear" color="dark" class="new-value">
          <ion-label [innerHtml]="keypadValue"></ion-label>
        </ion-button>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col>
        <ion-button size="large" (click)="numberButton('7')" expand="block" fill="outline" color="dark" class="ion-margin-horizontal">
          <ion-label>7</ion-label>
        </ion-button>
      </ion-col>

      <ion-col>
        <ion-button size="large" (click)="numberButton('8')" expand="block" fill="outline" color="dark" class="ion-margin-horizontal">
          <ion-label>8</ion-label>
        </ion-button>
      </ion-col>

      <ion-col>
        <ion-button size="large" (click)="numberButton('9')" expand="block" fill="outline" color="dark" class="ion-margin-horizontal">
          <ion-label>9</ion-label>
        </ion-button>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col>
        <ion-button size="large" (click)="numberButton('4')" expand="block" fill="outline" color="dark" class="ion-margin-horizontal">
          <ion-label>4</ion-label>
        </ion-button>
      </ion-col>

      <ion-col>
        <ion-button size="large" (click)="numberButton('5')" expand="block" fill="outline" color="dark" class="ion-margin-horizontal">
          <ion-label>5</ion-label>
        </ion-button>
      </ion-col>

      <ion-col>
        <ion-button size="large" (click)="numberButton('6')" expand="block" fill="outline" color="dark" class="ion-margin-horizontal">
          <ion-label>6</ion-label>
        </ion-button>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col>
        <ion-button size="large" (click)="numberButton('1')" expand="block" fill="outline" color="dark" class="ion-margin-horizontal">
          <ion-label>1</ion-label>
        </ion-button>
      </ion-col>

      <ion-col>
        <ion-button size="large" (click)="numberButton('2')" expand="block" fill="outline" color="dark" class="ion-margin-horizontal">
          <ion-label>2</ion-label>
        </ion-button>
      </ion-col>

      <ion-col>
        <ion-button size="large" (click)="numberButton('3')" expand="block" fill="outline" color="dark" class="ion-margin-horizontal">
          <ion-label>3</ion-label>
        </ion-button>
      </ion-col>
    </ion-row>

    <ion-row>

      <ion-col>
        <ion-button size="large" (click)="numberButton('00')" expand="block" fill="outline" color="dark" class="ion-margin-horizontal">
          <ion-label>00</ion-label>
        </ion-button>
      </ion-col>

      <ion-col>
        <ion-button size="large" (click)="numberButton('0')" expand="block" fill="outline" color="dark" class="ion-margin-horizontal">
          <ion-label>0</ion-label>
        </ion-button>
      </ion-col>

      <ion-col>
        <ion-button size="large" (click)="backspaceButton()" expand="block" fill="outline" color="dark" class="ion-margin-horizontal">
          <ion-icon color="dark" name="arrow-back"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col class="ion-no-margin ion-no-padding" size="4">
        <ion-button size="large" (click)="resetKeypad()" expand="block" fill="outline" color="danger" class="ion-margin-horizontal ion-margin-top">
          <ion-label>Reset</ion-label>
        </ion-button>
      </ion-col>
      <ion-col class="ion-no-margin ion-no-padding">
        <ion-button size="large" (click)="submitKeypad()" type="submit" expand="block" fill="outline" color="positive"
          class="ion-margin-horizontal ion-margin-top">
          <ion-label>Submit</ion-label>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-item>