<ion-content padding scrollY="false">
  <ion-row class="keypad full-height ion-align-items-center ion-justify-content-center">

    <ion-col>

      <ion-row class="logo ion-align-items-center ion-justify-content-center">
        <ion-col size="12" size-sm="9" size-md="6">
          <ion-img src="../../../assets/images/pen.png" padding ></ion-img>
        </ion-col>
      </ion-row>

      <ion-row class="ion-justify-content-center">

          <ion-card class="login">
            <ion-card-header>
              <ion-card-title>Session timeout</ion-card-title>
            </ion-card-header>

            <ion-card-content>
              <ion-label [innerHTML]="message"></ion-label>

              <ion-progress-bar [value]="progress"></ion-progress-bar>

              <ion-button expand="block" color="danger" (click)="logout()" fill="outline">
                Lock Now
              </ion-button>
  
              <ion-button expand="block" (click)="closeModal()">
                Continue Session
              </ion-button>
            </ion-card-content>

          </ion-card>
      </ion-row>
    </ion-col>
  </ion-row>

</ion-content>