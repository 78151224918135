import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { Subject, BehaviorSubject } from 'rxjs';
import { MenuTree } from '../../interfaces/menuTree';

@Injectable({
  providedIn: 'root'
})
export class ParseListProvider {

  public treeObservable: Subject<MenuTree[]>;
  // public current: Parse.Object;
  public current: BehaviorSubject<Parse.Object | false>;

  private collections: object = {};
  private localCollections: object = {};
  private validAttributes: false | string[];
  private currentList: Parse.Object;

  constructor() {
    this.treeObservable = new Subject();
    this.current = new BehaviorSubject(false);
  }

  initTree() {
    new Parse.Query('List')
      .greaterThanOrEqualTo('level', 2)
      .limit(1000)
      .find()
      .then(results => this.buildTree(results));
  }

  buildTree(lists) {
    const groupedLists = this.groupListsByParent(lists);
    const tree = this.generateTreeRecursive(groupedLists, groupedLists[0]);
    this.treeObservable.next(tree);
  }

  groupListsByParent(lists: Array<Parse.Object>): object {
    return lists.reduce((map, obj: Parse.Object) => {
      let parentId = 0;

      if (obj.get('parent') && obj.get('level') > 2) {
        parentId = obj.get('parent').id;
      }

      if (map.hasOwnProperty(parentId)) {
        map[parentId].push(obj);
      } else {
        map[parentId] = [obj];
      }

      return map;
    }, {});
  }

  generateTreeRecursive(
    grouped: object,
    rootNodes: Array<Parse.Object>
  ): Array<MenuTree> {
    const tree = [];
    for (const key in rootNodes) {
      if (!rootNodes[key]) {
        continue;
      }

      const obj = rootNodes[key];
      const node: MenuTree = {
        id: obj.id,
        object: obj,
        name: obj.get('name'),
      };
      const childNode = grouped[node.id];

      if (!node && !rootNodes.hasOwnProperty(key)) {
        continue;
      }

      if (childNode) {
        const childIds = [];
        childNode.forEach(child => {
          childIds.push(child.id);
        });
        node.childIds = childIds;
        node.children = this.generateTreeRecursive(grouped, childNode);
      }

      tree.push(node);
    }
    return tree;
  }

  subscribe(
    id: string,
    sortField: string,
    sortDescending: boolean,
    childIds?: Array<string>,
    filters?: any
  ) {
    if (!this.collections.hasOwnProperty(id)) {
      this.collections[id] = new Subject();
    }

    this.getCollection(id, sortField, sortDescending, childIds, filters);
    return this.collections[id];
  }

  async getCollection(
    id: string,
    sortField: string,
    sortDescending: boolean,
    childIds?: Array<string>,
    filters?: any
  ) {
    let results;

    if (this.localCollections[id]) {
      results = await this.getLocalCollection(id, sortField, sortDescending, filters);
    } else {
      results = await this.getRemoteCollection(id, sortField, sortDescending, childIds, filters);
      await Parse.Object.pinAll(results);
    }

    const filteredResults = await this.filterWorkingConfigurableAttributes(results, id);

    this.collections[id].next(filteredResults);
  }

  async getLocalCollection(
    id: string,
    sortField: string,
    sortDescending: boolean,
    filters?: any
  ) {
    const query = new Parse.Query('Item');
    query.containedIn('objectId', this.localCollections[id]);
    query.limit(1000);

    if (filters && filters.price) {
      query.greaterThan('price', filters.price.lower);
      query.lessThan('price', filters.price.upper);
    }

    if (sortDescending) {
      query.addDescending(sortField);
    } else {
      query.addAscending(sortField);
    }

    query.fromLocalDatastore();
    return await query.find();
  }

  async getRemoteCollection(
    id: string,
    sortField: string,
    sortDescending: boolean,
    childIds?: Array<string>,
    filters?: any
  ) {
    const innerQuery = new Parse.Query('List');

    if (childIds && childIds.length) {
      childIds.push(id);
      innerQuery.containedIn('objectId', childIds);
    } else {
      innerQuery.equalTo('objectId', id);
    }

    const query = new Parse.Query('Item');
    query.matchesQuery('lists', innerQuery);
    query.containedIn('visibility', ['2', '4']);
    query.containedIn('type', ['simple', 'configurable']);
    query.limit(1000);

    if (filters && filters.price) {
      query.greaterThan('price', filters.price.lower);
      query.lessThan('price', filters.price.upper);
    }

    if (sortDescending) {
      query.addDescending(sortField);
    } else {
      query.addAscending(sortField);
    }

    return await query.find();
  }

  async filterWorkingConfigurableAttributes(
    items: Parse.Object[],
    id: string
  ): Promise<Parse.Object[]> {
    this.localCollections[id] = [];
    const result: Parse.Object[] = [];

    for (const item of items) {
      this.localCollections[id].push(item.id);
      const children = await item.get('children');
      if (
          children
          && (await this.checkAttributes(children))
      ) {
        console.log('invalid configurable options', item.id);
      } else {
        result.push(item);
      }
    }

    return result;
  }

  async checkAttributes(
    children: any[]
  ): Promise<boolean> {
    const validAttributes: string[]|false = await this.getValidAttributes();
    if (validAttributes) {
      return this.checkForProperty(children, validAttributes);
    }
    return false;
  }

  checkForProperty(
    children: any[],
    validAttributes: string[]
  ): boolean {
    const length = validAttributes.length;
    for (const child of children) {
      const count = this.attributeCount(child, validAttributes);
      if (count < length) {
        return true;
      }
    }
    return false;
  }

  attributeCount(
    child: any,
    validAttributes: string[]
  ): number {
    let counter = 0;
    for (const attribute of validAttributes) {
      if (child.hasOwnProperty(attribute)) {
        counter++;
      }
    }
    return counter;
  }

  async getValidAttributes(): Promise<string[]|false> {
    if (this.validAttributes) {
      return this.validAttributes;
    }

    let result: string[]|false = false;
    const parseConfig = await Parse.Config.get();

    if (parseConfig) {
      result = parseConfig.get('valid_attributes');
    } else {
      console.log('parse config valid_attributes not set');
    }

    this.validAttributes = result;
    return result;
  }

  fetch(id: string) {
    const query = new Parse.Query('List');
    query.equalTo('objectId', id);
    query.first()
      .then(result => {
        this.currentList = result;
        this.current.next(this.currentList);
      },
      error => {
        console.log(error);
      });
  }

  async resetLocalDatastore() {
    await Parse.Object.unPinAllObjects();
    this.localCollections = {};
  }

}
