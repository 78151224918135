import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { Drawer } from '../../interfaces/drawer';

@Injectable({
  providedIn: 'root'
})
export class ParseDrawerProvider {

  async create(label: string) {
    const Drawer = Parse.Object.extend('Drawer');
    const drawer = new Drawer();

    drawer.set('label', label);
    return drawer.save();
  }

  async float(float: number, input: Parse.Object) {
    const now = new Date();
    const query = new Parse.Query('Drawer');
    const result = await query.get(input.id);
    result.set('float', float);
    result.set('sessionStarted', now);
    return result.save();
  }

  async unsetFloat(input: Parse.Object) {
    const query = new Parse.Query('Drawer');
    const result = await query.get(input.id);
    result.unset('float');
    result.unset('sessionStarted');
    return result.save();
  }

  async load(input: Drawer) {
    const query = new Parse.Query('Drawer');
    query.equalTo('objectId', input.drawer.id);
    return query.first();
  }

  async all() {
    const query = new Parse.Query('Drawer');
    query.descending('createdAt');
    query.include('locked');
    query.include('tradingSession');
    return await query.find();
  }

  async delete(input: Drawer) {
    const query = new Parse.Query('Drawer');
    const result = await query.get(input.drawer.id);
    return result.destroy();
  }

  async getDeviceArray(input: Drawer) {
    const query = new Parse.Query('Drawer');
    const result = await query.get(input.drawer.id);
    return result.get('devices');
  }

  async setDeviceArray(
    device: Object, 
    input: Parse.Object
  ): Promise<Parse.Object> {
    let devices: Object[] = [];
    const query = new Parse.Query('Drawer');
    const result = await query.get(input.id);
    if (result.get('devices')) {
      devices = result.get('devices');
    }
    devices.push(device);
    result.set('devices', devices);
    return await result.save();
  }

  async checkDeviceArray(device: Object, input: Parse.Object) {
    const query = new Parse.Query('Drawer');
    query.get(input.id);
    query.equalTo('devices', device);
    return query.first();
  }

  async deleteFromArray(device: any, input: Parse.Object) {
    const query = new Parse.Query('Drawer');

    query.get(input.id);
    const result = await query.first();
    const resultDevices: any[] = result.get('devices');
    let i = 0;
    resultDevices.forEach(resultDevice => {
      if (resultDevice.id === device.id) {
        resultDevices.splice(i, 1);
      }
      i++;
    });
    result.set('devices', resultDevices);
    return result.save();
  }

  async unsetDevices(input: Parse.Object) {
    input.unset('devices');
    return input.save();
  }

  async setTradingSession(
    drawer: Parse.Object,
    tradingSession: Parse.Object
  ): Promise<Parse.Object> {
    drawer.set('tradingSession', tradingSession);
    const result = await drawer.save();
    return result;
  }

  async unsetTradingSession(
    drawer: Parse.Object
  ): Promise<Parse.Object> {
    drawer.unset('tradingSession');
    const result = await drawer.save();
    return result;
  }

}
