import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)},
  { path: '', loadChildren: () => import('./pages/view/view.module').then(m => m.ViewPageModule), canLoad: [AuthGuard] },
  { path: 'settings', loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule), canLoad: [AuthGuard] },
  { path: 'view', loadChildren: () => import('./pages/view/view.module').then(m => m.ViewPageModule), canLoad: [AuthGuard] },
  { path: 'checkout', loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutPageModule), canLoad: [AuthGuard] },
  { path: 'success/:id/:change', loadChildren: () => import('./pages/success/success.module').then(m => m.SuccessPageModule), canLoad: [AuthGuard] },
  { path: 'return', loadChildren: () => import('./pages/return/return.module').then(m => m.ReturnPageModule), canLoad: [AuthGuard] }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
