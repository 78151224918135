import { Injectable, OnInit, NgZone } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';
import { CartItemCollection } from '../../interfaces/cartItemCollection';
import * as Parse from 'parse';
import { Message } from '../../interfaces/message';
import { Subject } from 'rxjs';
import { Customer } from '../../interfaces/customer';

@Injectable({
  providedIn: 'root'
})
export class ParseCartProvider implements OnInit {

  public saved: any = {};
  public objectKeys = Object.keys;
  public messages: Subject<Message> = new Subject();
  public checkoutMode = false;

  constructor(
    private alertController: AlertController,
    private loadingController: LoadingController,
    private zone: NgZone
  ) {
  }

  ngOnInit() {
  }

  save(
    name: string,
    cartItems: CartItemCollection,
    customer: Customer
  ) {
    if (Object.keys(cartItems).length) {
      this.loadByName(name)
        .then(results => {
          if (results.length) {
            const existingCart = results[0];
            existingCart.set('cartItems', cartItems);
            existingCart.set('customer', customer);
            existingCart.save()
              .then(this.cartWasSaved.bind(this))
              .catch(this.cartSaveError.bind(this));
          } else {
            const Cart = Parse.Object.extend('Cart');
            const cart = new Cart();
            cart.set('name', name);
            cart.set('cartItems', cartItems);
            cart.set('customer', customer);
            cart.save()
              .then(this.cartWasSaved.bind(this))
              .catch(this.cartSaveError.bind(this));
          }
        });
    } else {
      console.log('No items in cart to save');
    }
  }

  cartWasSaved() {
    this.zone.run(() => {
      this.messages.next({
        type: 'save'
      });
      this.getAll();
      this.loadingController.dismiss();
    });
  }

  cartSaveError(error: any) {
    console.log(error);
    this.messages.next({
      type: 'error',
      data: error
    });
  }

  loadByName(name: string) {
    const query = new Parse.Query('Cart');
    query.equalTo('name', name);
    return query.find();
  }

  async deleteCartPrompt(cartId: string) {
    const alert = await this.alertController.create({
      header: 'Are you sure you want to delete this cart?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {}
        }, {
          text: 'Yes',
          handler: () => {
            this.delete(cartId);
          }
        }
      ]
    });
    alert.present();
  }

  async delete(cartId: string) {
    const query = new Parse.Query('Cart');
    const cart = await query.get(cartId);
    return await cart.destroy();
  }

  async getAll() {
    this.saved = {};
    const query = new Parse.Query('Cart');
    query.descending('createdAt');
    const carts = await query.find();
    carts.forEach(cart => {
      this.saved[cart.id] = [cart.get('name'), cart];
    });
    return this.saved;
  }

  async presentLoading(message: string) {
    const loading = await this.loadingController.create({
      message: message
    });
    loading.present();
  }

  async updateParseCart(
    items: CartItemCollection, 
    customer: Customer,
    cart: Parse.Object
  ) {
    const query = new Parse.Query('Cart');
    const existingCart = await query.get(cart.id);
    existingCart.set('cartItems', items);
    existingCart.set('customer', customer);
    return await existingCart.save();
  }

}
