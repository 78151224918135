import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { Block } from '../../interfaces/block';
import { LiteBlock } from '../../interfaces/liteBlock';

@Injectable({
  providedIn: 'root'
})
export class ParseBlockProvider {

  private localBlocks: string[] = [];

  async set(
    info: Block,
    device: Parse.Object
  ): Promise<Parse.Object> {
    this.resetLocalBlocks();

    const BlockObject = Parse.Object.extend('Block');
    const block = new BlockObject();
    block.set('image', info.image);
    block.set('showImage', info.showImage);
    block.set('title', info.title);
    block.set('invert', info.invert);
    block.set('device', device);
    block.set('mandatory', info.mandatory);
    if (info.subtitle) { block.set('subtitle', info.subtitle); }
    block.set('bgEnabled', info.bgEnabled);
    if (info.backgroundColour) { block.set('backgroundColour', info.backgroundColour); }
    if (info.target) { block.set('target', info.target); }
    return await block.save();
  }

  async get(
    objectId: string
  ): Promise<Parse.Object> {
    const query = new Parse.Query('Block');
    return await query.get(objectId);
  }

  async getByDevice(
    device: Parse.Object
  ) {
    console.log('ParseBlockProvider :: getByDevice');

    if (this.localBlocks.length) {
      return this.getByDeviceFromLocal();
    }

    const deviceBlocks = new Parse.Query('Block');
    deviceBlocks.equalTo('device', device);

    const mandatoryBlocks = new Parse.Query('Block');
    mandatoryBlocks.equalTo('mandatory', true);

    const query = Parse.Query.or(deviceBlocks, mandatoryBlocks);
    query.descending('updatedAt');

    const blocks = await query.find();
    Parse.Object.pinAll(blocks);

    for(const block of blocks) {
      this.localBlocks.push(block.id);
    }

    return blocks;
  }

  async getByDeviceFromLocal() {
    console.log('ParseBlockProvider :: getByDeviceFromLocal');
    const query = new Parse.Query('Block');
    query.containedIn('objectId', this.localBlocks);
    query.fromLocalDatastore();
    return await query.find();
  }

  async delete(
    input: Parse.Object
  ) {
    this.resetLocalBlocks();
    const query = new Parse.Query('Block');
    const result = await query.get(input.id);
    return result.destroy();
  }

  async update(
    input: Parse.Object,
    info: Block
  ) {
    this.resetLocalBlocks();
    const query = new Parse.Query('Block');
    const block = await query.get(input.id);

    block.set('image', info.image);
    block.set('showImage', info.showImage);
    block.set('title', info.title);
    block.set('invert', info.invert);
    block.set('mandatory', info.mandatory);
    if (info.subtitle) { block.set('subtitle', info.subtitle); }
    block.set('bgEnabled', info.bgEnabled);
    if (info.backgroundColour) { block.set('backgroundColour', info.backgroundColour); }
    if (info.target) { block.set('target', info.target); }
    return await block.save();
  }

  resetLocalBlocks() {
    this.localBlocks = [];
  }

  async createLiteBlock(
    input: LiteBlock,
    device: Parse.Object
  ): Promise<Parse.Object> {
    const LiteBlockObject = Parse.Object.extend('LiteBlock');
    const liteBlock = new LiteBlockObject();
    liteBlock.set('icon', input.icon);
    liteBlock.set('color', input.color);
    liteBlock.set('title', input.title);
    liteBlock.set('order', input.order);
    liteBlock.set('device', device);
    (input.subtitle) ? liteBlock.set('subtitle', input.subtitle) : null;
    (input.target) ? liteBlock.set('target', input.target) : null;
    return await liteBlock.save();
  }

  async getDeviceLiteBlocks(
    device: Parse.Object
  ): Promise<Parse.Object[]> {
    const query = new Parse.Query('LiteBlock');
    // ignoring the device for testing, ie: get all the blocks from parse
    // query.equalTo('device', device);
    return await query.find();
  }

  async getLiteBlock(
    id: string
  ) {
    const query = new Parse.Query('LiteBlock');
    return await query.get(id);
  }
}
