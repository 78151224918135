import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})

export class ParseTaxProvider {

  private rates: Parse.Object[];

  constructor(
    private navCtrl: NavController
  ) {}

  async getAll() {
    if (!this.rates) {
      try {
        const query = new Parse.Query('Tax');
        query.descending('createdAt');
        this.rates = await query.find();
      } catch(e) {
        this.handleParseError(e);
      }
    }

    return this.rates;
  }

  async init() {
    const hasRates = await this.getAll();
    if (hasRates.length === 0) {
      try {
        const Tax = Parse.Object.extend('Tax');
        const tax = new Tax();
        tax.set('label', 'VAT');
        tax.set('rate', 20);
        console.log('setting tax rate');
        return await tax.save();
      } catch(e) {
          this.handleParseError(e);
        }  
    } else {
        return true;
    }
  }

  handleParseError(error) {
    console.log('handling parse error', error);
    switch (error.code) {
      case Parse.Error.INVALID_SESSION_TOKEN:
        console.log('invalid session token, logging out');
        Parse.User.logOut();
        this.navCtrl.navigateRoot('/');
        break;
    }
  }

  async get(
    label: string
  ) { 
    const query = new Parse.Query('Tax');
    query.equalTo('label', label);
    return await query.first();
  }
  
}
