import { NgModule } from "@angular/core";
import { EscapeUrlPipe } from "./keepurl/keepurl.pipe";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";

@NgModule({
    imports: [
      CommonModule,
      IonicModule
    ],
    declarations: [
      EscapeUrlPipe
    ],
    exports: [
      EscapeUrlPipe
    ]
})
export class PipesModule {}