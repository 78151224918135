import { Component, OnInit, Input } from '@angular/core';
import { ModalController, ToastController, LoadingController } from '@ionic/angular';
import { CurrencyProvider } from '../../providers/currency.provider';
import { CartProvider } from '../../providers/cart.provider';
import { ParseProvider } from '../../providers/parse.provider';
import * as JsSearch from 'js-search';
import * as Parse from 'parse';
import { UriProvider } from '../../providers/uri.provider';
import { CartItem } from '../../interfaces/cartItem';

@Component({
  selector: 'app-discount',
  templateUrl: './discount.page.html',
  styleUrls: ['./discount.page.scss'],
})

export class DiscountPage implements OnInit {

  @Input() item: CartItem;
  
  public keypadValue = '0.00';
  public itemKey = '';
  public priceEdited = false;
  public keypadVisible = false;
  public keypadType: string;

  constructor(
    public currencyProvider: CurrencyProvider,
    private modalCtrl: ModalController,
    private cartProvider: CartProvider,
    private toastController: ToastController,
    private loadingController: LoadingController,
    private parseProvider: ParseProvider,
    private uriProvider: UriProvider
  ) {
  }

  ngOnInit() {
    this.getItemKey(this.item);
    this.priceEdited = (this.item.originalPrice) ? true : false;
  }

  getItemKey(
    item: CartItem
  ) {
    this.itemKey = this.cartProvider.getItemKey(item.item, item.options);
  }

  openKeypad(type: string) {
    if (this.item.originalPrice) {
      switch (type) {
        case 'price' : 
          this.keypadValue = this.item.price;
          break;
        case 'discount' :
          let discount = parseFloat(this.item.originalPrice) - parseFloat(this.item.price);
          this.keypadValue = this.formatPrice(discount.toString(), false);
          break;
        case 'percentage' :
          let percentage = ((parseFloat(this.item.originalPrice) - parseFloat(this.item.price)) / parseFloat(this.item.originalPrice)) * 100;
          this.keypadValue = this.formatPrice(percentage.toString(), false);
          break;
      }
    }
    this.keypadType = type;
    this.keypadVisible = true;
  }

  updateValueFromKeypad(event) {
    this.keypadValue = event;
    this.submitKeypad();
  }

  submitKeypad() {
    this.updatePrice(this.keypadValue, this.keypadType);
    this.resetKeypad();
  }

  resetKeypad() {
    this.keypadVisible = false;
    this.keypadValue = "0.00";
  }

  async updatePrice(
    input: string,
    type: string
  ) {
    const price = (this.priceEdited) ? this.item.originalPrice : this.item.price;
    let newPrice = '';
    switch (type) {
      case 'price' :
        newPrice = input;
        break;
      case 'discount' :
        const discountedPrice = parseFloat(price) - parseFloat(input);
        newPrice = this.formatPrice(discountedPrice.toString(), false);
        break;
      case 'percentage' :
        const percentageAmount = parseFloat(price) * (parseFloat(input) / 100);
        const percentagePrice = parseFloat(price) - percentageAmount;
        newPrice = this.formatPrice(percentagePrice.toString(), false);
        break;
    }
    this.priceEdited = await this.cartProvider.updatePrice(this.itemKey, newPrice);
  }

  resetPrice() {
    this.cartProvider.resetPrice(this.itemKey);
    this.priceEdited = false;
  }

  formatPrice(price: string | number, showSymbol: boolean = true) {
    if (typeof price === 'string') {
      price = parseFloat(price);
    }

    return this.currencyProvider.transform(price, showSymbol);
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  getDifference(
    original: string,
    final: string
  ): number {
    return parseFloat(original) - parseFloat(final);
  }

  getPercent(
    original: string,
    final: string
  ): number {
    return (this.getDifference(original, final) / parseFloat(original)) * 100;
  }

}
