import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { EventLog } from '../../interfaces/eventLog';

@Injectable({
  providedIn: 'root'
})

export class ParseEventProvider {

  async create(
    eventLog: EventLog
  ): Promise<Parse.Object> {
    const EventObject = Parse.Object.extend('Event');
    const event = new EventObject();
    const eventLogKeys = Object.keys(eventLog);
    for (const key in eventLogKeys) {
      const keyValue = eventLogKeys[key];
      event.set(keyValue, eventLog[keyValue]);
    }
    const result = await event.save();
    return result;
  }

  async get(
    start: Date,
    end: Date,
    type: string,
    filterType?: string,
    filterObject?: Parse.Object
  ): Promise<Parse.Object[]> {
    const query = new Parse.Query('event');
    query.lessThanOrEqualTo('updatedAt', end);
    query.greaterThanOrEqualTo('updatedAt', start);
    query.equalTo('type', type);
    if (filterType) {
      query.equalTo(filterType, filterObject);
    }
    const result = await query.find();
    return result;
  }
}

