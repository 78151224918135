export const environment = {
  production: true,
  parseAppId: 'e91ff6f731c7f8fc969826cf66d4219f644b70bc',
  parseServerUrl: 'https://live-ejc-22185.nodechef.com/parse',
  currency: 'GBP',
  currencySymbol: '\u00A3',
  appId: 'mdlpos',
  project: 'ejc',
  version: '3.0.0',
  published: '2024-08-28'
};
