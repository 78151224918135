<ion-header>
  <ion-toolbar>
    <ion-title>{{cartName}}</ion-title>
    <ion-buttons slot="end">
      <ion-button fill="clear" (click)="closeModal()">Dismiss</ion-button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>

<ion-content [fullscreen]="false">
  <ion-list *ngFor="let item of cartItems">
    <ion-item>{{item.name}} x {{item.qty}} | £{{item.price}}</ion-item>
  </ion-list>
</ion-content>