import { Injectable } from '@angular/core';
import * as Parse from 'parse';


@Injectable({
    providedIn: 'root'
})
export class ParsePaymentProvider {
    async getTerminals(username: string): Promise<Array<string>> {
        return Parse.Cloud.run('backend-payment::getTerminals', {
            username: username
        });
    }

    async startTransaction(username: string, tid: string, type: string, amount: number): Promise<string> {
        return Parse.Cloud.run('backend-payment::startTransaction', {
            username: username,
            tid: tid,
            type: type,
            amount: amount
        });
    }

    async getTransaction(username: string, tid: string, requestId: string): Promise<Object> {
        return Parse.Cloud.run('backend-payment::getTransaction', {
            username: username,
            tid: tid,
            requestId: requestId
        });
    }

    async verifySignature(username: string, tid: string, requestId: string, accept: boolean) {
        return Parse.Cloud.run('backend-payment::verifySignature', {
            username: username,
            tid: tid,
            requestId: requestId,
            accept: accept
        });
    }

    async cancelTransaction(username: string, tid: string, requestId: string) {
        return Parse.Cloud.run('backend-payment::cancelTransaction', {
            username: username,
            tid: tid,
            requestId: requestId
        });
    }

    async startReport(username: string, tid: string, type: string) {
        return Parse.Cloud.run('backend-payment::startReport', {
            username: username,
            tid: tid,
            type: type
        })
    }

    async getReport(username: string, tid: string, requestId: string) {
        return Parse.Cloud.run('backend-payment::getReport', {
            username: username,
            tid: tid,
            requestId: requestId
        })
    }

    async logPayment(orderId: string, value: number) {
        return Parse.Cloud.run('backend-payment::logPayment', {
            orderId: orderId,
            value: value
        })
    }

    async linkPayment(requestId: string, paymentId: string, txn: Object) {
        return Parse.Cloud.run('backend-payment::linkPayment', {
            requestId: requestId,
            paymentId: paymentId,
            txn: txn
        })
    }
}

