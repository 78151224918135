import { Injectable } from '@angular/core';
import { ParseProvider } from './parse.provider';
import { EventLog } from '../interfaces/eventLog';
import { DeviceProvider } from './device.provider';
import * as Parse from 'parse';


@Injectable({
  providedIn: 'root'
})
export class EventProvider {

  constructor(
    private parseProvider: ParseProvider,
    private deviceProvider: DeviceProvider,

  ) {
  }

  async create(
    type: string,
    noDevice?: boolean
  ): Promise<Parse.Object> {
    let eventLog: EventLog;
    const device = (noDevice) ? null : await this.deviceProvider.getCurrent('event Provider :: create');
    const user = await Parse.User.currentAsync();
    const drawer = (device && await device.get('drawer')) ? await device.get('drawer') : null;
    const store = (device && await device.get('store')) ? await device.get('store') : null;
    eventLog = {
      store: store,
      user: user,
      device: device,
      drawer: drawer,
      type: type,
      description: null,
      additional: null
    };
    return await this.parseProvider.event.create(eventLog);
  }

  async get(
    start: Date,
    end: Date,
    type: string,
    filterType?: string,
    filterObject?: Parse.Object
  ): Promise<Parse.Object[]> {
    return await this.parseProvider.event.get(start, end, type, filterType, filterObject);
  }

}
