import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NetworkProvider {

  private online = true;
  private offlineCount = 0;
  private offlineLimit = 3;

  public status: BehaviorSubject<boolean>;
  public retrying: BehaviorSubject<boolean>;

  constructor() {
    this.status = new BehaviorSubject(this.online);
    this.retrying = new BehaviorSubject(true);
  }

  setOnline() {
    this.offlineCount = 0;
    this.retrying.next(true);

    if (!this.online) {
      this.online = true;
      this.broadcast();
    }

    return true;
  }

  setOffline() {
    this.offlineCount++;
    const shouldRetry = (this.offlineCount < this.offlineLimit);
    const retriesRemaining = this.offlineLimit - this.offlineCount;

    this.retrying.next(shouldRetry);

    console.log(`Health check failed ${this.offlineCount} times, automatically retrying ${retriesRemaining} more times`);

    if (this.online) {
      this.online = false;
      this.broadcast();
    }

    return shouldRetry;
  }

  broadcast() {
    this.status.next(this.online);
  }

}
