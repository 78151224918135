import { Injectable } from '@angular/core';
import * as Parse from 'parse';

@Injectable({
  providedIn: 'root'
})
export class ParseJobProvider {

  constructor() {
  }

  public async run(jobName) {
    return Parse.Cloud.run('backend-job::start', { jobName });
  }

  public async checkStatus(jobId) {
    return Parse.Cloud.run('backend-job::getStatus', { jobId });
  }
}
