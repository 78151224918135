<ion-content padding scrollY="false">
  <ion-row class="keypad full-height ion-align-items-center ion-justify-content-center">

    <ion-col>

      <ion-row class="logo ion-align-items-center ion-justify-content-center">
        <ion-col size="12" size-sm="9" size-md="6">
          <ion-img src="../../../assets/images/pen.png" padding ></ion-img>
        </ion-col>
      </ion-row>

      <ion-row class="login ion-justify-content-center">
        <ion-col size="10">
          <ion-card color="light" class="login-card">
            <ion-list>
              <form (ngSubmit)="userPassLogin()" (keydown.enter)="$event.preventDefault()">
                <ion-item-group class="ion-margin-bottom">
                  <ion-item lines="full">
                    <ion-input #usernameInput (keyup.enter)="setFocus(passwordInput)" placeholder="Username" type="text" name="username" [(ngModel)]="user.username" required
                      autocapitalize="off" autocomplete="off" autocorrect="off" aria-label="username" data-cy="username"></ion-input>
                  </ion-item>
                  <ion-item lines="full">
                    <ion-input #passwordInput (keyup.enter)="userPassLogin()" placeholder="Password" type="password" name="password" [(ngModel)]="user.password" aria-label="password" required data-cy="password"></ion-input>
                  </ion-item>
                </ion-item-group>
                <ion-row class="ion-justify-content-center" *ngIf="type !== 'elevate'">
                  <ion-button expand="block" fill="clear" type="submit" data-cy="loginButton">
                    <ion-icon slot="start" name="log-in"></ion-icon>
                    <ion-label slot="end">Login</ion-label>
                  </ion-button>
                </ion-row>
                <ion-row class="ion-justify-content-center" *ngIf="type == 'elevate'">
                  <ion-button color="danger" expand="block" fill="clear" (click)="closeModal()" >
                    <ion-icon slot="start" name="close"></ion-icon>
                    <ion-label slot="end">Cancel</ion-label>
                  </ion-button>
                  <ion-button expand="block" fill="clear" type="submit">
                    <ion-icon slot="start" name="arrow-forward"></ion-icon>
                    <ion-label slot="end">Approve</ion-label>
                  </ion-button>
                </ion-row>
              </form>
            </ion-list>
            <!-- <ion-row class="ion-justify-content-center">
              <ion-button (click)="resetPassword()" color="medium" fill="clear" size="small">
                <ion-icon slot="start" name="help-buoy"></ion-icon>
                <ion-label slot="end">Forgot?</ion-label>
              </ion-button>
            </ion-row> -->
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>

</ion-content>