import { Injectable } from '@angular/core';
import { ParseProvider } from './parse.provider';
import { Subject } from 'rxjs';

interface ResultsById {
}

@Injectable({
  providedIn: 'root'
})
export class SearchProvider {

  public results: Parse.Object[] = [];
  public searchTerms: string[] = [];
  public searchString = '';
  public listIds: Array<string> | null | false;
  public resultsUpdated: Subject<Parse.Object[]> = new Subject();

  private resultsById: ResultsById;
  private sortField: string;
  private sortDescending: boolean;

  constructor(
    private parseProvider: ParseProvider
  ) {
    this.parseProvider.search.resultsUpdated.subscribe(this.onResultsUpdate);
  }

  onResultsUpdate = (results) => {
    this.reset();
    this.iterateResults(results);
    this.results = this.sortResults();
    this.resultsUpdated.next(this.results);
  }

  getSearchResults(
    search: string,
    listIds?: Array<string> | null | false,
    sortField?: string,
    sortDescending?: boolean,
  ) {
    if (
      search === this.searchString
      && sortField === this.sortField
      && sortDescending === this.sortDescending
      && JSON.stringify(listIds) === JSON.stringify(this.listIds)
    ) {
      this.resultsUpdated.next(this.results);
      return;
    }

    // TODO :: this, but better
    if (this.sortField !== 'relevance') {
      this.sortField = sortField;
      this.sortDescending = sortDescending;
    } else {
      delete this.sortField;
      delete this.sortDescending;
    }

    this.searchString = search;
    this.listIds = listIds;
    const words = search.split(' ');
    this.searchTerms = [search];
    this.searchTerms = this.searchTerms.concat(words.filter(word => word.length > 1));
    this.parseProvider.search.fullText(['name', 'sku'], this.searchTerms, this.listIds, sortField, sortDescending);
  }

  iterateResults(results: Array<any>) {
    results.forEach(item => {
      this.collateResults(item);
    });
  }

  collateResults(item: Parse.Object) {
    if (this.resultsById[item.id]) {
      this.resultsById[item.id].weight += 1;
    } else {
      const newResult = {
        item: item,
        weight: 1,
        name: item.get('name')
      };
      this.resultsById[item.id] = newResult;
    }
  }

  sortResults() {
    const results = [];
    Object.keys(this.resultsById).forEach(id => {
      results.push(this.resultsById[id].item);
    });
    return (this.sortField) 
      ? results
      : results.sort(this.sortByWeight);
  }

  sortByWeight = (a, b) => {
    if (this.resultsById[a.id].weight > this.resultsById[b.id].weight) {
      return -1;
    }
    if (this.resultsById[a.id].weight < this.resultsById[b.id].weight) {
      return 1;
    }
    return 0;
  }

  reset() {
    this.results = [];
    this.resultsById = {};
  }

}
