import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationProvider } from '../providers/authentication.provider';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(
    private router: Router,
    private authenticationProvider: AuthenticationProvider
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authenticationProvider.isLoggedIn();
  }

  canLoad(
    route: ActivatedRouteSnapshot
  ): boolean {
    const allow = this.authenticationProvider.isLoggedIn();
    // const allow = true;

    if (!allow) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
}
