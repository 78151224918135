import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import * as Parse from 'parse';
import { ParseUserProvider } from './parse/user.provider';
import { ParseItemProvider } from './parse/item.provider';
import { ParseListProvider } from './parse/list.provider';
import { ParseCartProvider } from './parse/cart.provider';
import { ParseCustomerProvider } from './parse/customer.provider';
import { ParseOrderProvider } from './parse/order.provider';
import { ParseSearchProvider } from './parse/search.provider';
import { ParseDrawerProvider } from './parse/drawer.provider';
import { ParseDeviceProvider } from './parse/device.provider';
import { ParseTradingProvider } from './parse/trading.provider';
import { ParseReportProvider } from './parse/report.provider';
import { ParseBlockProvider } from './parse/block.provider';
import { ParseLayoutProvider } from './parse/layout.provider';
import { ParseReturnProvider } from './parse/return.provider';
import { ParseTaxProvider } from './parse/tax.provider';
import { ParsePaymentProvider } from './parse/payment.provider';
import { ParseColourProvider } from './parse/colour.provider';
import { NetworkProvider } from './network.provider';
import { ParseStoreProvider } from './parse/store.provider';
import { ParseEventProvider } from './parse/event.provider';
import { ParseJobProvider } from './parse/job.provider';

@Injectable({
  providedIn: 'root'
})
export class ParseProvider {

  private config: Parse.Config;

  constructor(
    public user: ParseUserProvider,
    public item: ParseItemProvider,
    public list: ParseListProvider,
    public cart: ParseCartProvider,
    public customer: ParseCustomerProvider,
    public order: ParseOrderProvider,
    public search: ParseSearchProvider,
    public drawer: ParseDrawerProvider,
    public device: ParseDeviceProvider,
    public trading: ParseTradingProvider,
    public report: ParseReportProvider,
    public block: ParseBlockProvider,
    public layout: ParseLayoutProvider,
    public tax: ParseTaxProvider,
    public payment: ParsePaymentProvider,
    public returnOrder: ParseReturnProvider,
    public colour: ParseColourProvider,
    private networkProvider: NetworkProvider,
    public store: ParseStoreProvider,
    public event: ParseEventProvider,
    public job: ParseJobProvider
  ) {
    this.initialiseParse();
    this.initialiseHeartbeat();
  }

  initialiseParse() {
    try {
      Parse.initialize(environment.parseAppId);
      (Parse as any).serverURL = environment.parseServerUrl;
      Parse.enableLocalDatastore();
    } catch (e) {
      this.handleParseError(e);
    }
  }

  initialiseHeartbeat() {
    this.runHeartbeat();
  }

  async runHeartbeat() {
    let beat = false;

    try {
      const result = await Parse.Cloud.run('backend-health::heartbeat');

      if (!result) {
        throw new Error('Network Issue :: Parse API heartbeat failed');
      }

      beat = this.networkProvider.setOnline();
    } catch (e) {
      if (e.code === Parse.Error.CONNECTION_FAILED) {
        beat = this.networkProvider.setOffline();
      } else {
        beat = true;
      }
    }

    if (beat) {
      setTimeout(() => {
        this.runHeartbeat();
      }, 20000);
    }
  }

  handleParseError(error) {
    switch (error.code) {
      case Parse.Error.INVALID_SESSION_TOKEN:
        console.log('invalid session token, logging out');
        Parse.User.logOut();
        break;
    }
  }

  async getConfigValue(key: string) {
    if (!this.config) {
      this.config = await Parse.Config.get();
    }
    return this.config.get(key);
  }

  async resetLocalDatastore() {
    console.log(await Parse.dumpLocalDatastore());
    await Parse.Object.unPinAllObjects();
    console.log(await Parse.dumpLocalDatastore());
  }

  async saveConfigValue(key: string, value: any) {
    await Parse.Cloud.run('backend-config::save', {
      key: key,
      value: value
    });
  }
}
