import { Injectable } from '@angular/core';
import * as Parse from 'parse';

@Injectable({
  providedIn: 'root'
})
export class ParseReturnProvider {

  async create(details: any = {}): Promise<Parse.Object> {
    const ReturnOrder = Parse.Object.extend('ReturnOrder');
    const returnOrder = new ReturnOrder();
    returnOrder.set('order', details.order);
    returnOrder.set('items', details.items);
    returnOrder.set('payments', details.payments);
    returnOrder.set('drawer', details.drawer);
    return await returnOrder.save();
  }

  async getForOrder(id: string) {
    const innerQuery = new Parse.Query('Order');
    innerQuery.equalTo('objectId', id);
    const query = new Parse.Query('ReturnOrder');
    query.matchesQuery('order', innerQuery);
    return query.find();
  }

  //Cannot use pointer object as param in cloud function so separate function
  async createCloud(id: string): Promise<Parse.Object> {
    return Parse.Cloud.run('backend-order::refund', {
      id: id
    });
  }

  async updateRefundStatus(order: Parse.Object, payments: Parse.Object[], refunds: Parse.Object[]) {
    let orderTotal = 0;
    let refundTotal = 0;

    for (const payment of payments) {
      orderTotal += payment.get('amount');
    }

    for (const refund of refunds) {
      for (const refundPayment of refund.get('payments')) {
        refundTotal += refundPayment.amount;
      }
    }

    const status = orderTotal == refundTotal ? 'Full' : 'Partial';
    order.set('refunded', status)
    await order.save();
  }

}
