<ion-header class="item item-header">
  <ion-toolbar class="no-safe-area">
    <ion-grid>
      <ion-label></ion-label>
      <ion-row class="ion-no-padding">
        <ion-col size="10">
          <ion-row class="name" [innerHtml]="item.get('name')"></ion-row>
          <ion-row class="price ion-no-padding"><span [innerHtml]="convertPrice(item.get('price'))"></span></ion-row>
        </ion-col>

        <ion-col size="2" (click)="closeItemModal()" class="done-button" text-end align-self-center>
          Done
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-header>


<ion-content [fullscreen]="true" class="item-modal" [ngClass]="{'simple': (selects.length == 0)}" [class.inline-image]="inlineImage">
  
  <ion-row *ngIf="inlineImage">
    <ion-col size="6">
      <swiper-container pager="true" pagination="true" class="item-image-slider" *ngIf="getImageUrl(item.get('image'))">
        <swiper-slide>
          <ion-img [src]="getImageUrl(item.get('image'))" ></ion-img>
        </swiper-slide>
    
        <swiper-slide *ngFor="let image of gallery">
          <ion-img [src]="image" ></ion-img>
        </swiper-slide>
      </swiper-container>
    </ion-col>
    <ion-col size="6">
      <ion-item *ngFor="let select of selects">
        <ion-select [ngClass]="{'validation-error': (showValidation && !itemShop[select.code])}" [(ngModel)]="itemShop[select.id]"
          [placeholder]="select.label" (ionChange)="selectedOptionsChange()" aria-label="variation-select">

          <ion-select-option *ngFor="let value of values[select.code]" 
            [ngClass]="{'disabled': !value.enabled}"
            [disabled]="!value.enabled" 
            [value]="value">
            {{value.label}}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <hr>
      <p [innerHtml]="item.get('shortDescription')"></p>
      <hr>
    </ion-col>
  </ion-row>
  
  <swiper-container pager="true" pagination="true" class="item-image-slider" *ngIf="getImageUrl(item.get('image')) && !inlineImage">
    <swiper-slide>
      <ion-img [src]="getImageUrl(item.get('image'))" ></ion-img>
    </swiper-slide>

    <swiper-slide *ngFor="let image of gallery">
      <ion-img [src]="image" ></ion-img>
    </swiper-slide>
  </swiper-container>

  <ion-toolbar *ngIf="selects.length !== 0 && !inlineImage">
    <ion-row>
      <ion-col *ngFor="let select of selects">
        <ion-select [ngClass]="{'validation-error': (showValidation && !itemShop[select.code])}" [(ngModel)]="itemShop[select.id]"
          [placeholder]="select.label" (ionChange)="selectedOptionsChange()" aria-label="variation-select">

          <ion-select-option *ngFor="let value of values[select.code]" 
            [ngClass]="{'disabled': !value.enabled}"
            [disabled]="!value.enabled" 
            [value]="value">
            {{value.label}}
          </ion-select-option>

        </ion-select>
      </ion-col>
    </ion-row>
  </ion-toolbar>

  <ion-card>
    <ion-card-content>
      <p [innerHtml]="item.get('description')"></p>
    </ion-card-content>
  </ion-card>
</ion-content>

<ion-footer class="tab-footer">
  <ion-buttons class="ion-justify-content-between ion-padding-horizontal">
    <ion-button color="medium">
      <ion-select placeholder="Qty" [(ngModel)]="qty" class="quantity-select" aria-label="quantity-select">
        <ion-select-option *ngFor="let quantity of quantities" [value]="quantity.value">{{quantity.value}}</ion-select-option>
      </ion-select>
    </ion-button>

    <ion-button (click)="addToCart()">
      <ion-label>Add</ion-label>
      <ion-icon name="arrow-forward" slot="end"></ion-icon>
    </ion-button>
  </ion-buttons>
</ion-footer>