import { Injectable } from '@angular/core';
import { Customer } from '../interfaces/customer';

@Injectable({
    providedIn: 'root'
})

export class DiscountProvider {
    public discounts = {};

    async getDiscounts(customer: Customer) {
        const group = customer['group']; 
        const discounts = group.get('discounts');

        if (discounts) {
            for (let discount of discounts) {
                discount = await discount.fetch();
                if (discount.get('discounted')) {
                    this.discounts[discount.get('itemGroup')] = discount.get('discount');
                }
            }
        }

        return this.discounts;
    }
}