import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { DeviceInfo, Device as capacitorDevice } from '@capacitor/device';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ParseDeviceProvider {

  public defaultIdle = 120;
  public defaultTimeout = 20;

  constructor(
    private navCtrl: NavController
  ) { }

  async get(
    uuid: string,
    unauthenticated?: boolean
  ): Promise<Parse.Object> {
    const query = new Parse.Query('Device');
    query.equalTo('uuid', uuid);

    if (!unauthenticated) {
      query.include('drawer');
    }

    return await query.first();
  }

  async simpleGet(
    uuid: string
  ): Promise<Parse.Object> {
    const query = new Parse.Query('Device');
    query.equalTo('uuid', uuid);
    query.include('drawer');
    return await query.first();
  }

  async set(info: DeviceInfo) {

    try {
      const Device = Parse.Object.extend('Device');
      const device = new Device();
      const deviceId = await capacitorDevice.getId();

      device.set('uuid', deviceId.identifier);
      device.set('model', info.model);
      device.set('manufacturer', info.manufacturer);
      device.set('info', info);
      device.set('printerWarning', false);
      device.set('speechEnabled', false);
      device.set('speechLanguage', 'en-GB');
      device.set('idle', this.defaultIdle); // How long you can be idle for in seconds
      device.set('timeout', this.defaultTimeout); // After the idle period it will timeout in this amount of seconds
      device.set('cartTax', true); // Decide whether to show tax in the cart or not
      device.set('taxLabel', 'VAT');
      device.set('onAccount', true);
      device.set('imagesEnabled', true);
      device.set('persistentSideCart', true);
      device.set('tapAddToCartEnabled', true);
      device.set('inlineImage', true);
      device.set('customBarcode', true);
      device.set('cartSharing', false);
      device.set('liteBlocks', false);

      const result = await device.save();

      return result;
    } catch (e) {
      this.handleParseError(e);
    }
  }

  async drawer(
    device: Parse.Object,
    drawer: Parse.Object
  ): Promise<Parse.Object> {
    device.set('drawer', drawer);

    return await device.save();
  }

  async unsetDrawer(device: Parse.Object) {
    device.unset('drawer');

    return device.save();
  }

  async unsetDrawers(drawer: Parse.Object) {
    const query = new Parse.Query('Device');
    query.equalTo('drawer', drawer);
    const result = await query.find();

    if (result) {
      result.forEach(device => {
        device.unset('drawer');
        device.save();
      });
    }

    return result;
  }

  handleParseError(error) {
    console.log('handling parse error', error);
    switch (error.code) {
      case Parse.Error.INVALID_SESSION_TOKEN:
        console.log('invalid session token, logging out');
        Parse.User.logOut();
        this.navCtrl.navigateRoot('/');
        break;
    }
  }

  async getCurrent(unauthenticated?: boolean): Promise<Parse.Object> {
    const deviceInfo = await capacitorDevice.getId();
    const device = await this.get(deviceInfo.identifier, unauthenticated);

    return device;
  }

  async getFromPointerArray(
    devicePointerArray: Parse.Pointer[]
  ): Promise<Parse.Object[]> {
    const query = new Parse.Query('Device');
    let deviceIds: string[] = [];

    for (let i = 0; i < devicePointerArray.length; i++) {
      const devicePointer = devicePointerArray[i];
      const deviceId = devicePointer['id'];
      deviceIds.push(deviceId);
    }

    query.containedIn('objectId', deviceIds);

    return await query.find();
  }

}
