import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { ParseProvider } from './parse.provider';
import { ReturnItem } from '../interfaces/returnItem';
import { LoadingProvider } from './loading.provider';

@Injectable({
  providedIn: 'root'
})
export class ReturnProvider {

  private currentReturn: any;

  constructor(
    private parseProvider: ParseProvider,
    private loadingProvider: LoadingProvider
  ) {
  }

  async create(details: any = {}): Promise<Parse.Object | false> {
    let response: Parse.Object | false = false;

    try {
      response = await this.parseProvider.returnOrder.create(details);
      await this.loadingProvider.present('Refunding');
      await this.parseProvider.returnOrder.createCloud(response.id);
      await this.updateRefundStatus(details.order)
    } catch (e) {
      console.error(e);
    }

    await this.loadingProvider.dismiss();

    return response;
  }

  setCurrentReturn(order: Parse.Object | false, items?: ReturnItem[]) {
    if (order) {
      this.currentReturn = {
        order: order,
        items: items
      };
    } else {
      this.currentReturn = false;
    }
  }

  getCurrentReturn(): any {
    return this.currentReturn;
  }

  getForOrder(id) {
    return this.parseProvider.returnOrder.getForOrder(id);
  }

  async updateRefundStatus(order: Parse.Object) {
    const payments = await this.parseProvider.order.getPayments(order.id)
    const refunds = await this.getForOrder(order.id)
    await this.parseProvider.returnOrder.updateRefundStatus(order, payments, refunds);
  }

}
