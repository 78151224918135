import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { Subject } from 'rxjs';
import { Message } from '../../interfaces/message';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ParseUserProvider {

  public messages: Subject<Message>;

  constructor(
    private toastController: ToastController
  ) {
    this.initialiseObservables();
  }

  initialiseObservables() {
    this.messages = new Subject();
  }

  async login(
    username: string,
    password: string,
    token?: any,
    type?: string
  ): Promise<any | false> {
    console.log('Parse Provider User :: Login - type: ', type);
    const user = token && (type == 'login' || type == 'timeout') ? await this.become(token) : await this.check(username, password);

    if (user) {
      this.messages.next({
        type: type,
        data: {
          user: user,
          token: token
        }
      });

      return user;
    }
  }

  async check(
    username: string,
    password: string,
    token?: any
  ): Promise<Parse.User | false> {
    let user: Parse.User | false;

    try {
      if (token) {
        user = await Parse.User.logIn(username, password, { sessionToken: token });
      } else {
        user = await Parse.User.logIn(username, password);
      }
    } catch (e) {
      this.toast(e.message);
      console.log(e.message);
    }

    return user;
  }

  async getUser(username) {
    const query = new Parse.Query(Parse.User);
    query.equalTo('username', username);

    return await query.first();
  }

  async become(
    token: any
  ): Promise<Parse.User | false> {
    let user: Parse.User | false = false;

    try {
      user = await Parse.User.become(token);
    } catch (e) {
      this.toast(e.message);
      console.log(e.message);
    }

    return user;
  }


  async logout() {
    try {
      await Parse.User.logOut();
      this.messages.next({
        type: 'logout'
      });
    } catch (error) {
      this.onError('logoutError', error);
    }
  }

  log(message: string) {
    console.log('ParseUserProvider :: ' + message);
  }

  async resetPassword(email: string) {
    try {
      const data = await Parse.User.requestPasswordReset(email);
      this.messages.next({
        type: 'resetPassword',
        data: data
      });
    } catch (error) {
      this.onError('resetPasswordError', error);
    }
  }

  onError(
    type: string,
    error: any
  ) {
    console.log(type, error);
    this.messages.next({
      type: type,
      error: error
    });
  }

  async getCurrentUser() {
    return await Parse.User.current();
  }

  async toast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      position: 'top',
      duration: 2000
    });

    toast.present();
  }

  async create(
    username: string,
    password: string,
    email: string,
    manager: boolean
  ): Promise<Parse.Object> {
    const user = new Parse.User();
    user.set("username", username);
    user.set("password", password);
    user.set("firstTime", true);
    user.set("manager", manager);
    user.set("email", email);

    try {
      const result = await user.signUp();

      return result;
    } catch (error) {
      await this.toast(error.message);
      console.log("Error: " + error.code + " " + error.message);
    }
  }

  async getAll(): Promise<Parse.Object[]> {
    const query = new Parse.Query('User');
    query.limit(1000);
    query.descending('username');
    const result = await query.find();

    return result;
  }

  async verifyPassword(username, password) {
    const p = require('parse'); //require until @types/parse is updated with verifyPassword

    try {
      return await p.User.verifyPassword(username, password);
    } catch (error) {
      this.toast(error.message);
    }
  }
}
