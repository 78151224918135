import { Component } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { IdleService } from './services/idle.service';
import { register } from 'swiper/element/bundle';

const { SplashScreen, Device } = Plugins;

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  constructor(
    private idleService: IdleService
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    this.idleService.run();
    const device = await Device.getInfo();

    if (device.platform !== 'web') {
      SplashScreen.hide();
    }
  }

}
