import { Injectable } from '@angular/core';
import * as Parse from 'parse';

@Injectable({
  providedIn: 'root'
})
export class ParseItemProvider {

  get(id: string): Promise<Parse.Object> {
    const query = new Parse.Query('Item');
    return query.get(id);
  }

  log(message: string) {
    console.log('ParseProductProvider :: ' + message);
  }

  getByBarcode(barcode: string): Promise<Parse.Object> {
    const query = new Parse.Query('Item');
    query.equalTo('barcode', barcode);
    const query2 = new Parse.Query('Item');
    query2.equalTo('barcode2', barcode);

    const compoundQuery = Parse.Query.or(query, query2);
    const result = compoundQuery.first();

    return result;
  }

  /**
   * Find parent product from child
   * 
   * @param {Parse.Object} product
   * @returns {Promise<Object}
   */
  getParent(product: Parse.Object): Promise<Parse.Object> {
    const query = new Parse.Query('Item');
    let child = {
      'entityId': product.get('entityId')
    }

    child['itemId'] = product.id;

    for (let attr of product.get('attributes')) {
      child[attr['name']] = attr['option']
    }

    query.equalTo('children', child);
    const result = query.first();
    return result;
  }

  /**
   * Get options when scanning a variation product of a configurable product
   * 
   * @param {Parse.Object} product
   * @param {Parse.Object} parent
   * @returns {Object}
   */
  getOptions(product: Parse.Object, parent: Parse.Object): Object {
    let options = {}

    for (let attr of parent.get('configurableAttributes')) {
      options[attr.attribute_id] = {
        attribute: attr,
        enabled: true,
      }
    }

    for (let attr of product.get('attributes')) {
      options[attr.id]['id'] = attr.option;
      options[attr.id]['label'] = attr.option;
    }

    return options
  }

  async getDistinctValues(attribute: string) {
    return Parse.Cloud.run('backend-item::distinct', {
      attribute: attribute
  });
  }
}
