import { Injectable } from '@angular/core';
import * as Parse from 'parse';
import { Store } from '../../interfaces/store';

@Injectable({
  providedIn: 'root'
})

export class ParseStoreProvider {

  async get(
    storeId: string
  ): Promise<Parse.Object> {
    const query = new Parse.Query('Store');
    const result = await query.get(storeId);
    return result;
  }

  async getAll(): Promise<Parse.Object[]> {
    const query = new Parse.Query('Store');
    query.limit(1000);
    query.descending('name');
    const result = await query.find();
    return result;
  }

  async create(
    storeInput: Store
  ): Promise<Parse.Object> {
    const StoreObject = Parse.Object.extend('Store');
    const store = new StoreObject();
    const storeKeys = Object.keys(storeInput);
    for (const key in storeKeys) {
      const keyValue = storeKeys[key];
      store.set(keyValue, storeInput[keyValue]);
    }
    const result = await store.save();
    return result
  }

  async delete(
    storeId: string
  ): Promise<Parse.Object> {
    const query = new Parse.Query('Store');
    const store = await query.get(storeId);
    const result = await store.destroy();
    return result;
  }

  async exists(
    storeId: string
  ): Promise<boolean> {
    let result = false;
    const query = new Parse.Query('Store');
    query.equalTo('objectId', storeId);
    const storeArray = await query.find();
    const length = storeArray.length;
    result = (length > 0);
    return result;
  }

}
