<ion-header>
  <ion-toolbar class="no-safe-area">
    <ion-grid>
      <ion-row>
        <ion-col (click)="closeCartModal()" class="done-button" text-end align-self-center>
          Done
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list class="ion-no-margin">
    <ion-item class="item__barcode" [ngClass]="{'failure': (barcodeSuccess === 'failure'), 'success': (barcodeSuccess === 'success')}">
      <form (ngSubmit)="barcodeAdd()">
        <input type="url" name="barcode" [(ngModel)]="barcode" placeholder="Enter barcode ..." />
        <ion-button [hidden]="true" type="submit"></ion-button>
      </form>
    </ion-item>
  </ion-list>

  <ng-container *ngIf="getCartLength()" class="ion-padding">
    <ion-list #cartList>
      <ion-item-sliding *ngFor="let key of objectKeys(cartProvider.items)">
        <ion-item>
          <ion-avatar slot="start">
            <ion-img *ngIf="getImageUrl(cartProvider.items[key])" [src]="getImageUrl(cartProvider.items[key])" ></ion-img>
            <ion-img *ngIf="!getImageUrl(cartProvider.items[key])" src="/assets/icon/favicon.png" ></ion-img>
          </ion-avatar>

          <ion-label *ngIf="!smallDevice">
            <h2 [innerHtml]="cartProvider.items[key].name"></h2>
            <ion-chip class="option-chip" color="primary">
              <ion-label [innerHtml]="convertPrice(cartProvider.items[key].price)"></ion-label>
            </ion-chip>
            <ng-container *ngIf="cartProvider.items[key].options">
              <ion-chip *ngFor="let optionKey of objectKeys(cartProvider.items[key].options)" class="option-chip">
                <ion-label [innerHtml]="cartProvider.items[key].options[optionKey].attribute.frontend_label + ' : ' + cartProvider.items[key].options[optionKey].label"></ion-label>
              </ion-chip>
            </ng-container>
          </ion-label>

          <ion-label *ngIf="smallDevice">
            <h2 [innerHtml]="cartProvider.items[key].name"></h2>
            <p [innerHtml]="convertPrice(cartProvider.items[key].price)"></p>
            <ng-container *ngIf="cartProvider.items[key].options">
              <p *ngFor="let optionKey of objectKeys(cartProvider.items[key].options)" class="option-chip" [innerHtml]="cartProvider.items[key].options[optionKey].attribute.frontend_label + ' : ' + cartProvider.items[key].options[optionKey].label"></p>
            </ng-container>
          </ion-label>

          <ion-badge slot="end">{{cartProvider.items[key].qty}}</ion-badge>
          <ion-buttons>
            <ion-button (click)="incrementQty(key)" color="medium" fill="outline">
              <ion-icon slot="icon-only" name="add"></ion-icon>
            </ion-button>
            <ion-button (click)="decrementQty(key)" color="medium" fill="outline">
              <ion-icon slot="icon-only" name="remove"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-item>

        <ion-item-options side="end">
          <ion-item-option (click)="removeProduct(key)" color="danger">
            <ion-icon slot="icon-only" name="trash"></ion-icon>
          </ion-item-option>
          <ion-item-option (click)="setQtyPopup(key, cartProvider.items[key].qty, cartList)" color="primary">
            <ion-icon slot="icon-only" name="keypad"></ion-icon>
          </ion-item-option>
          <ion-item-option *ngIf="!cartProvider.items[key].noDiscount" (click)="discount(cartProvider.items[key], cartList)" color="warning">
            <ion-icon slot="icon-only" name="create"></ion-icon>
          </ion-item-option>
        </ion-item-options>
      </ion-item-sliding>
    </ion-list>
  </ng-container>

  <ng-container *ngIf="!getCartLength()">
    <ion-row class="ion-justify-content-center ion-align-items-center">
      <ion-col class="ion-text-center ion-padding-top">
        No items in cart
      </ion-col>
    </ion-row>
  </ng-container>
</ion-content>

<ion-footer *ngIf="!getCartLength()">
  <ion-toolbar>
    <ion-buttons class="ion-justify-content-evenly">
      <ion-button color="medium" (click)="openLoadCartsActionSheet()">
        <ion-icon slot="start" name="cloud-download"></ion-icon>
        Load
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>

<ion-footer *ngIf="getCartLength()">

  <ion-toolbar *ngIf="cartProvider.showCheckoutButton">
    <ion-button expand="block" (click)="checkout()" margin-horizontal>
      <ion-label>
        Checkout <span [innerHtml]="currencyProvider.transform(cartProvider.getTotalPrice())"></span>
      </ion-label>
    </ion-button>
  </ion-toolbar>

  <ion-toolbar>
    <ion-buttons class="ion-justify-content-evenly">
      <ion-button color="medium" (click)="setCartNamePopup()">
        <ion-icon slot="start" name="cloud-upload"></ion-icon>
        Save
      </ion-button>
      <ion-button color="medium" (click)="openLoadCartsActionSheet()">
        <ion-icon slot="start" name="cloud-download"></ion-icon>
        Load
      </ion-button>
      <ion-button color="danger" (click)="clearCart()">
        <ion-icon slot="start" name="trash"></ion-icon>
        Clear
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>