import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UriProvider {

  constructor() {
  }

  forceHttps(
    original: string
  ): string {
    const httpsCheck = original.slice(0, 5);
    if (httpsCheck === 'https') {
      return original;
    } else {
      return this.insertCharacter(original, 's', 4);
    }
  }

  insertCharacter(
    original: string,
    insert: string,
    position: number
  ): string {
    const result = original.slice(0, position) + insert + original.slice(position);
    return result;
  }
}
