import { Injectable } from '@angular/core';
import { AuthenticationProvider } from './authentication.provider';
import { LoadingProvider } from './loading.provider';
import { ModalController } from '@ionic/angular';
import { AuthPage } from '../modals/auth/auth.page';

@Injectable({
  providedIn: 'root'
})
export class ElevationProvider {

  constructor(
    public authenticationProvider: AuthenticationProvider,
    private loadingProvider: LoadingProvider,
    private modalCtrl: ModalController
  ) {
  }

  async elevate() {
    let result = false;
    const user = await this.authenticationProvider.getCurrentUser();
    const manager = user.get('manager');
    if (!manager) {
      result = await this.elevateModal();
    } else {
      result = true;
    }
    return result;
  }

  async elevateModal(): Promise<boolean> {
    let elevated = false;
    await this.loadingProvider.present();
    const modal = await this.initModal(null, 'elevate', null);
    await this.loadingProvider.dismiss();
    elevated = await modal.onDidDismiss().then(async () => {
      return await this.checkIfElevated();
    });
    console.log('Elevation Provider :: elevateModal :: result', elevated);
    return elevated;
  }

  async initModal(
    data: any,
    type: string,
    title: string
  ): Promise<HTMLIonModalElement> {
    const modal = await this.modalCtrl.create({
      component: AuthPage,
      componentProps: {
        'type' : type,
        'data' : data,
        'title' : title
      },
      backdropDismiss: false,
      cssClass: ['authModal'],
      keyboardClose: true
    });
    modal.present();
    return modal;
  }

  async checkIfElevated() {
   const elevated = this.authenticationProvider.userSessionProvider.elevated;
   if (elevated) {
     console.log('function approved');
   } else {
     console.log('function not approved');
   }
   return elevated;
  }

}
